// variables
$white-color: #fff;
$primary-color: #9F1D21;
$secondary-color: #F3B01B;
$secondary-bg-color:#fffbf2;
$text-color: #7C848D;
$dark-text-color: #3A4149;

// $primary-color: #090202;
// $secondary-color: #090202;

// breakpoints
$too-small-mobile:320px;
$small-mobile: 376px;
$mobile: 576px;
$small-tab: 768px;
$big-tab: 992px;
$small-desktop: 1024px;
$desktop: 1200px;
$mid-desktop: 1400px;
$large-desktop: 1700px;
