@import "_variables";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap");

// mixins
@mixin too-small-mobile {
  @media (min-width: $too-small-mobile) {
    @content;
  }
}

@mixin small-mobile {
  @media (min-width: $small-mobile) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: $mobile) {
    @content;
  }
}

@mixin small-tab {
  @media (min-width: $small-tab) {
    @content;
  }
}

@mixin big-tab {
  @media (min-width: $big-tab) {
    @content;
  }
}

@mixin small-desktop {
  @media (min-width: $small-desktop) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin mid-desktop {
  @media (min-width: $mid-desktop) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: $large-desktop) {
    @content;
  }
}

@mixin center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: #f3b01a;
  text-decoration: none;
}

.border_bottom {
  border-bottom: 4px solid $secondary-color;
}

body {
  scroll-behavior: smooth;
  font-family: "Barlow", sans-serif;
  // font-family: 'Barlow Condensed', sans-serif;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

// keyframes
@keyframes wave {
  from {
    left: 50px;
    top: -30px;
  }

  to {
    left: 0px;
    top: 0px;
  }
}

@keyframes wave {
  0% {
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
  }

  100% {
    -webkit-box-shadow: 0 0 0px 50px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0px 50px rgba(255, 255, 255, 0);
  }
}

@keyframes arrow {
  0% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(-5deg);
  }
}

.wave {
  position: relative;
  animation: wave 2s infinite ease-in;
  border-radius: 50%;
}

@keyframes animateHeart {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

@keyframes animateRipple {
  0% {
    transform: scale(0.3);
    opacity: 0.5;
  }

  100% {
    transform: scale(20);
    opacity: 0;
  }
}

@keyframes scaler {
  0% {
    transform: scale(1);
    -webkit-box-shadow: 0 0 0px 150px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px 150px rgba(255, 255, 255, 0.5);
  }

  100% {
    transform: scale(1.5);
    -webkit-box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 0px 0px rgba(255, 255, 255, 0);
  }
}

.scaler {
  position: relative;
  animation: wave 2s infinite ease-out;
  border-radius: 50%;
}

:root {
  --toastify-icon-color-success: #f3b01b !important;
  --toastify-color-success: #f3b01b !important;
}

.fancybox__content {
  border: 2px solid $secondary-color;
}

.fancybox__container {
  --fancybox-thumbs-border-radius: 0;
  // --fancybox-thumbs-ratio: 1;
  // --fancybox-thumbs-width: 60px;
  // --fancybox-thumbs-height: 60px;
  --fancybox-accent-color: #f3b01b;

  .carousel__viewport {
    .carousel__track {
      .carousel__slide {
        &.is-nav-selected {
          .fancybox__thumb {
            &::after {
              border-width: 3px;
            }
          }
        }
      }
    }
  }
}

.dual-heading {
  h2 {
    color: $secondary-color;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 23px;

    @include mobile() {
      font-size: 30px;
    }

    @include big-tab() {
      font-size: 40px;
    }

    span {
      color: $primary-color;
    }
  }

  p {
    color: $text-color;
    margin-top: 15px;
    font-size: 20px;

    @include small-tab() {
      font-size: 22px;
    }
  }

  &.center {
    h2,
    p {
      text-align: center;
    }
  }
}

small.error {
  font-size: 12px;
  color: $primary-color;
}

.form-close-btn {
  border: 0;
  outline: 0;
  width: 40px;
  height: 40px;
  background-color: $secondary-color;
  color: $white-color;
  border-radius: 3px;

  @include big-tab() {
    margin-top: 34px;
  }

  svg {
    font-size: 20px;
  }
}

.foreign-donation-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 600;
  color: $primary-color;

  @include small-tab() {
    font-size: 20px;
    padding: 15px 25px;
  }

  span {
    img {
      width: 50px;
    }
  }
}

.html5-main-video {
  left: 0 !important;
  width: 100% !important;
}

.b-line {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 150px;
    height: 5px;
    background-color: $secondary-color;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.custom-btn {
  width: 200px;
  padding: 5px 8px;
  background-color: $secondary-color;
  color: $white-color;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;

  &.full-width {
    width: unset;
  }

  &:hover {
    color: $white-color;
  }

  &.thanks {
    @include mobile() {
      width: 300px;
    }
  }

  @include mobile {
    padding: 5px 8px;
  }

  @include small-tab() {
    padding: 6px 10px;
  }

  @include big-tab() {
    padding: 8px 12px;
  }
}

.custom-btn-cls {
  display: inline-block;
  transition: 0.4s;
  margin: 5px;
  font-size: 14px;
  padding: 6px 15px;
  background-color: $secondary-color;
  color: $white-color;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  text-decoration: none;

  &.animation {
    display: flex;
    align-items: center;
    overflow: hidden;

    .anination-wrap {
      margin-right: 2px;
      position: relative;
      width: 30px;
      height: 30px;
      @include center();

      .heart {
        animation: animateHeart 2.5s infinite;
        position: relative;
        z-index: 10;
        pointer-events: none;
      }

      .ripple {
        pointer-events: none;
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $white-color;
        top: 9px;
        z-index: 0;
        left: 9px;
        border-radius: 50%;
        animation: animateRipple 2.5s infinite 0.6s;
      }
    }
  }

  &.full-width {
    width: unset;
  }

  &:hover {
    color: $white-color;
  }

  @include mobile {
    padding: 5px 8px;
    font-size: 16px;
  }

  @include small-tab() {
    padding: 6px 10px;
  }

  @include big-tab() {
    margin: 10px;
    padding: 7px 10px;
    font-size: 18px;
  }

  &.full-width {
    width: unset;
  }

  &.donation_btn {
    padding: 7px 50px;
  }
}

section {
  position: relative;
  background-color: #fff;
  padding: 40px 0;
  z-index: 10;

  @include big-tab() {
    padding: 80px 0;
  }
}

.arrow-wrap {
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
  z-index: 15;

  button {
    width: 45px;
    height: 45px;
    border: 0;
    background-color: $secondary-color;
    position: relative;
    outline: 0;
    @include center();

    svg {
      font-size: 24px;
      color: $white-color;
    }
  }
}

.type_checkbox {
  display: flex;
  align-items: center;

  input {
    cursor: pointer;
    margin-right: 8px;
    min-width: 18px;
    height: 18px;
    border-radius: 0;
    border: 1px solid $secondary-color;
    position: relative;
    appearance: none;

    &:checked {
      &::before {
        opacity: 1;
      }
    }

    &::before {
      content: "✔";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include center();
      font-size: 12px;
      color: $white-color;
      background-color: $secondary-color;
      opacity: 0;
    }
  }

  label {
    cursor: pointer;
    font-size: 15px;
    color: $secondary-color;
  }
}

.required-field {
  &::after {
    content: '*';
    color: red;
  }
}

ul {
  li {
    font-size: 16px;
    font-family: "Barlow", sans-serif;
    color: $text-color;
    margin-bottom: 12px;

    @include small-tab() {
      font-size: 18px;
    }

    &.title {
      &::before {
        display: none;
      }

      font-weight: 500;
      margin-top: 20px;
    }

    a {
      text-decoration: none;
      color: $text-color;
      transition: 0.4s;

      &:hover {
        color: $secondary-color;
      }
    }

    &.bullet {
      padding-left: 25px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        background-image: url("../../public/images/feather.svg");
        background-position: center;
        background-size: cover;
        left: 0;
        top: 6px;
      }
    }
  }
}

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  // background-color: #ffffffe8;
  background-color: #fff4daf0;
  z-index: 100;
  transition: 0.4s;
  box-shadow: 0 10px 30px -20px #00000024;

  .mobile-top-menu {
    padding: 20px 0 10px;
    border-bottom: 1px solid #9f1d211f;

    @include big-tab() {
      display: none;
    }

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include small-tab() {
        display: grid;
        grid-template-columns: repeat(3, minmax(100px, 1fr));
      }

      li {
        margin-bottom: 0;

        &:nth-of-type(1) {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        &:nth-of-type(2) {
          @include center();
        }

        &:nth-of-type(3) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        a,
        button {
          font-size: 13px;
          border: 1px solid $primary-color;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 5px;
          color: $primary-color;

          @include small-mobile() {
            font-size: 16px;
          }

          @include mobile() {
            padding: 2px 8px;
          }

          svg {
            margin-right: 5px;
          }

          &.live {
            svg {
              color: #23f635;
            }
          }

          &.animation {
            .anination-wrap {
              margin-right: 2px;
              position: relative;
              width: 30px;
              height: 30px;
              @include center();

              .heart {
                animation: animateHeart 2.5s infinite;
                position: relative;
                z-index: 10;
                pointer-events: none;
                display: inline-block;
              }

              .ripple {
                pointer-events: none;
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: $white-color;
                top: 9px;
                z-index: 0;
                left: 9px;
                border-radius: 50%;
                animation: animateRipple 2.5s infinite 0.6s;
              }
            }
          }
        }
      }
    }
  }

  .top-nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 8px;

    @include small-tab() {
      padding-top: 5px;
      padding-bottom: 5px;
      grid-template-columns: 1fr 90px 1fr;
    }

    .logo {
      @include center();
      a {
        transition: 0.4s;
        @include center();
        width: 50px;
        
        @include mobile() {
          width: 67px;
          margin: auto;
        }

        &:focus-visible {
          outline: none;
        }

        @include big-tab() {
          width: 75px;
        }

        img {
          width: 100%;

          &:focus-visible {
            outline: none;
          }
        }
      }
    }

    ul {
      display: flex;
      align-items: center;

      li {
        margin-bottom: 0;
        padding: 4px;
      }

      &.social-icons {
        display: flex;
        justify-content: flex-end;
        // @include mobile() {
        //   display: flex;
        // }

        li {
          display: none;
          @include small-tab() {
            display: block;
          }
          &:last-child {
            a {
              margin-right: 0px;
            }
          }

          a {
            font-size: 16px;
            color: $primary-color;
            margin-right: 0px;
            transition: 0.4s;
            width: 30px;
            height: 30px;
            @include center();
            border: 1px solid $primary-color;
            @include mobile() {
              width: 35px;
            font-size: 20px;
              height: 35px;
              margin-right: 12px;
            }
            @include small-tab() {
              margin-right: 20px;
              font-size: 20px;
            }

            &:hover {
              color: $secondary-color;
              border: 1px solid $secondary-color;
            }
          }

          button {
            height: 35px;
            border: 1px solid $primary-color;
            background-color: transparent;
            padding: 0 8px;
            color: $primary-color;
            font-size: 18px;
            transition: 0.4s;

            svg {
              color: #23f635;
            }

            &:hover {
              border: 1px solid $secondary-color;
              color: $secondary-color;
            }
          }

          &.header-btn {
            a {
              width: auto;
              padding-inline: 10px;
            }
          }
        }
      }

      &.login-wrap {
        li {
          display: none;
          @include small-tab() {
            display: block;
          }
          button {
            border: 1px solid $primary-color;
            outline: 0;
            color: $primary-color;
            @include center();
            transition: 0.4s;
            background-color: #9f1d212b;
            display: none;
            height: 35px;
            padding: 2px 6px;

            @include small-tab() {
              display: flex;
              background-color: unset;
              font-size: 18px;
            }

            @include big-tab() {
              padding: 6px 20px;
              width: unset;
            }

            svg {
              path {
                transition: 0.4s;
              }
            }

            &:hover {
              color: $secondary-color;
              border: 1px solid $secondary-color;

              svg {
                path {
                  fill: $secondary-color !important;
                }
              }

              &.animation {
                svg {
                  path {
                    fill: $white-color !important;
                  }
                }
              }
            }

            svg {
              font-size: 20px;

              @include big-tab() {
                margin-right: 5px;
              }
            }

            span {
              display: none;

              @include big-tab() {
                display: block;
              }
            }

            &.toggle-side-menu {
              display: block;
              border: 0;
              outline: 0;
              width: 35px;
              height: 35px;
              background-color: transparent;
              border: 1px solid $primary-color;
              padding: 4px 0;
              margin-left: 10px;

              @include small-tab() {
                margin-left: 15px;
              }

              @include big-tab() {
                height: unset;
              }

              svg,
              i {
                font-size: 22px;
                color: $primary-color;
                transition: 0.4s;
                margin-right: 0;
                margin-bottom: 4px;

                @include mobile() {
                  margin-bottom: 7px;
                }
              }

              @include big-tab() {
                display: none;
              }
            }

            &.top-donate {
              width: auto;
              border: unset;
              background-color: $secondary-color;
              color: $white-color;
              display: flex;
              text-transform: uppercase;

              @include big-tab() {
                display: none;
              }
            }

            &.animation {
              border: 0;
              color: $white-color;
              overflow: hidden;
              display: flex;

              .anination-wrap {
                margin-right: 2px;
                position: relative;
                width: 30px;
                height: 30px;
                @include center();

                .heart {
                  animation: animateHeart 2.5s infinite;
                  position: relative;
                  z-index: 10;
                  pointer-events: none;
                  display: inline-block;
                }

                .ripple {
                  pointer-events: none;
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  background-color: $white-color;
                  top: 9px;
                  z-index: 0;
                  left: 9px;
                  border-radius: 50%;
                  animation: animateRipple 2.5s infinite 0.6s;
                  display: inline-block;
                }
              }
            }

            &.login-msg {
              position: relative;

              p {
                position: absolute;
                background-color: $secondary-color;
                width: 200px;
                right: 0;
                bottom: -100%;
                font-size: 14px;
                font-weight: 500;
                padding: 6px;
                color: $white-color;
                pointer-events: none;
                opacity: 0;
                transition: 0.4s;
                z-index: 11;

                &::before {
                  content: "";
                  position: absolute;
                  width: 12px;
                  height: 12px;
                  background-color: $secondary-color;
                  top: -5px;
                  right: 20px;
                  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                }
              }

              &:hover {
                p {
                  bottom: -120%;
                  opacity: 1;
                  pointer-events: all;
                }
              }
            }
          }
        }
      }
    }
  }

  .bottom-nav {
    border-top: 1px solid #e5baa959;
    ul {
      li {
        margin-bottom: 8px;
        margin-inline: 10px;
        a, button {
          border: 0;
          outline: 0;
          background-color: transparent;
          color: $primary-color;
          font-size: 14px;
        }
      }
    }
    @include small-tab() {
      display: none;
    }
  }

  nav {
    display: none;
    align-items: center;
    padding: 15px 0;
    justify-content: space-between;
    transition: 0.4s;
    position: relative;

    @include big-tab() {
      display: flex;
      justify-content: unset;
    }

    ul.nav-list {
      display: none;
      margin-bottom: 0;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      // border: 1px solid red;
      @include big-tab() {
        display: flex;
      }

      li {
        text-align: center;
        position: relative;
        margin-bottom: 0;

        &.drop-menu {
          &:hover {
            .sub-menu {
              top: 40px;
              pointer-events: all;
              opacity: 1;
            }

            button {
              &.drop-btn {
                color: $secondary-color;

                svg {
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }

        a,
        span.anc {
          text-transform: uppercase;
          font-size: 15px;
          font-weight: 500;
          cursor: pointer;

          &.custom-btn-cls {
            padding: 5px 10px;

            @include desktop() {
              padding: 10px;
            }
          }

          @include mid-desktop() {
            font-size: 15px;
            // padding: 0 20px 0 20px;
          }
        }

        .has-submenu {
          position: relative;

          span.anc {
            transition: 0.4s;
            display: flex;
            align-items: center;

            &:hover {
              color: $secondary-color;
            }

            svg {
              margin-bottom: 3px;
            }
          }

          &:hover {
            ul {
              opacity: 1;
              pointer-events: all;
            }
          }

          ul {
            position: absolute;
            width: 100%;
            left: 100%;
            top: 0;
            background-color: $white-color;
            padding: 5px 15px;
            box-shadow: 0 0 30px 8px #7c7c7c14;
            opacity: 0;
            pointer-events: none;
            transition: 0.4s;
          }
        }

        button {
          width: unset;
          padding: 8px 25px;

          &.drop-btn {
            text-decoration: none;
            font-size: 15px;
            font-weight: 500;
            color: $text-color;
            padding: 0 10px;
            text-transform: uppercase;
            border: 0;
            background-color: transparent;
            outline: 0;
            display: flex;
            align-items: center;

            @include small-desktop() {
              padding: 0 3px;
            }

            @include desktop() {
              padding: 0 10px;
            }

            // @include mid-desktop() {
            //   padding: 0 30px 0 20px;
            // }

            &:hover,
            &.active {
              color: $primary-color;
            }

            svg {
              margin-left: 3px;
              transform: rotate(0deg);
              transition: 0.3s;
            }
          }
        }

        .sub-menu {
          width: 220px;
          position: absolute;
          left: 0;
          padding: 5px 15px;
          background-color: $white-color;
          pointer-events: none;
          top: 30px;
          transition: 0.4s;
          opacity: 0;

          &::before {
            content: "";
            width: 100%;
            height: 20px;
            top: -18px;
            position: absolute;
            left: 0;
          }

          ul {
            li {
              text-align: left;
              width: 100%;
              margin-bottom: 0;
              padding-left: 26px;
              position: relative;

              &:nth-child(1) {
                width: unset;
              }

              &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                left: 0;
                top: 12px;
                background-image: url("../../public/images/feather.svg");
                background-size: cover;
                background-position: center;
              }

              a,
              span.anc {
                display: inline-block;
                padding: 10px 0;

                &.redirect-link {
                  display: flex;
                  align-items: center;

                  svg {
                    margin-left: 5px;
                    margin-bottom: 1px;
                  }
                }
              }
            }
          }
        }
      }

      .nav-btn {
        background-color: $secondary-color;
        padding: 10px 0;

        a {
          color: #fff;
          font-weight: 500;
        }
      }
    }

    .toggle-side-menu {
      display: block;
      border: 0;
      outline: 0;
      background-color: transparent;

      svg,
      i {
        font-size: 22px;
        color: $primary-color;
        transition: 0.4s;
      }

      @include big-tab() {
        display: none;
      }
    }

    .login {
      position: absolute;
      right: 18%;
      top: 0;
      padding: 6px 30px 8px;
      background-color: $white-color;
      color: $primary-color;
      border: 0;
      outline: 0;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;

      svg {
        margin-right: 5px;
        margin-bottom: 4px;
        font-size: 16px;
      }

      &::before,
      &::after {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        top: 0;
      }

      &::before {
        left: -20px;
        box-shadow: 5px -4px 0px 4px $white-color;
        border-radius: 0 30px 0 0;
      }

      &::after {
        border-radius: 30px 0 0 0;
        box-shadow: -5px -5px 0px 5px $white-color;
        right: -20px;
      }
    }
  }

  &.scroll {
    .top-nav {
      padding-top: 5px;

      .logo {
        a {
          width: 60px;
        }
      }
    }

    nav {
      padding: 5px 0;

      .login {
        display: none;
      }
    }
  }
}

.heading {
  text-align: center;
  line-height: 1.2;

  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 30px;
    font-weight: 700;

    @include mobile() {
      font-size: 40px;
    }
  }

  .head-2 {
    font-family: "Barlow Condensed", sans-serif;
    color: $primary-color;
    font-size: 30px;
    font-weight: 700;

    @include mobile() {
      font-size: 40px;
    }
  }

  p {
    margin-top: 15px;
    font-size: 20px;
    color: $text-color;
  }

  span.title-logo {
    display: block;
    width: 400px;
    margin: auto;
    width: 260px;

    @include small-tab() {
      margin-bottom: 20px;
      width: 350px;
    }

    @include big-tab() {
      width: 400px;
    }

    img {
      width: 100%;
    }
  }

  &._main {
    h2 {
      // background-color: $white-color;
      padding-top: 8px;
      padding-bottom: 8px;
      // border: 1px solid #f3b01b4f;
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        width: 60%;
        height: 2px;
        background-color: $secondary-color;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
  }
}

a {
  &:hover {
    color: $primary-color;
  }
}

.grecaptcha-badge {
  visibility: hidden;
  pointer-events: none;
}

.box-hover {
  position: relative;
  z-index: 10;

  &:focus-visible {
    outline: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $secondary-color;
    z-index: -1;
    transition: 0.4s;
  }

  &:hover {
    &::before {
      transform: scale(1.03);
    }
  }
}

.side-nav {
  display: block;
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  min-height: 100vh;
  background-color: #fffdf8;
  padding: 20px 0;
  z-index: 100;
  transition: 0.4s;

  @include big-tab() {
    display: none;
  }

  &.opened {
    left: 0%;
  }

  .side-nav-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 20px;

    figure {
      width: 60px;

      img {
        width: 100%;
      }
    }

    .side-menu-close {
      display: block;
      border: 0;
      outline: 0;
      background-color: transparent;

      svg,
      i {
        font-size: 22px;
        color: $primary-color;
        transition: 0.4s;
      }

      @include big-tab() {
        display: none;
      }

      &:hover {
        svg,
        i {
          color: $secondary-color;
        }
      }
    }

    .side-nav-left-header {
      // border: 1px solid red;
      display: flex;
      align-items: center;

      .side-nav-donate {
        width: 130px;
        padding: 8px 12px;
        background-color: $secondary-color;
        color: $white-color;
        border: 0;
        outline: 0;
        text-transform: uppercase;
        // margin-left: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        overflow: hidden;

        &::before {
          display: none;
        }

        .anination-wrap {
          margin-right: 2px;
          position: relative;
          width: 30px;
          height: 30px;
          @include center();

          .heart {
            animation: animateHeart 2.5s infinite;
            position: relative;
            z-index: 10;
            pointer-events: none;
          }

          .ripple {
            pointer-events: none;
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: $white-color;
            top: 9px;
            z-index: 0;
            left: 9px;
            border-radius: 50%;
            animation: animateRipple 2.5s infinite 0.6s;
          }
        }
      }
    }
  }

  .side-nav-body {
    border-top: 1px solid #9f1d2173;

    .menu-scroll-wrap {
      overflow-y: auto;
      max-height: 100vh;
    }

    ul {
      // border-bottom: 1px solid #9f1d2173;
      margin-bottom: 200px;
      padding-bottom: 20px;

      li {
        &.main-list {
          a.main-anc {
            padding: 12px 20px;
            display: block;
            width: 100%;
            position: relative;
            text-decoration: none;
            font-size: 16px;
            color: $text-color;
            text-transform: uppercase;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 5px;
              background-color: #fffdf8;
              transition: 0.4s;
            }

            &:hover,
            &.active {
              background-color: #f3b01b33;
              color: $primary-color;

              &::before {
                background-color: $secondary-color;
              }
            }
          }

          button {
            padding: 12px 20px;
            border-radius: 0;
            position: relative;
            font-size: 16px;
            color: $text-color;
            text-transform: uppercase;

            &:focus {
              box-shadow: unset;
            }

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 5px;
              background-color: #fffdf8;
              transition: 0.4s;
            }

            &:hover,
            &.active {
              background-color: #f3b01b33;
              color: $primary-color;

              &::before {
                background-color: $secondary-color;
              }
            }

            &.side-nav-donate {
              width: 130px;
              padding: 8px 12px;
              background-color: $secondary-color;
              color: $white-color;
              border: 0;
              outline: 0;
              text-transform: uppercase;
              margin-left: 20px;
              // margin-top: 12px;
              display: flex;
              align-items: center;
              overflow: hidden;

              &::before {
                display: none;
              }

              .anination-wrap {
                margin-right: 2px;
                position: relative;
                width: 30px;
                height: 30px;
                @include center();

                .heart {
                  animation: animateHeart 2.5s infinite;
                  position: relative;
                  z-index: 10;
                  pointer-events: none;
                }

                .ripple {
                  pointer-events: none;
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  background-color: $white-color;
                  top: 9px;
                  z-index: 0;
                  left: 9px;
                  border-radius: 50%;
                  animation: animateRipple 2.5s infinite 0.6s;
                }
              }
            }

            &.login_btn {
              width: unset;

              svg {
                font-size: 24px;
                margin-right: 5px;
              }
            }

            &.login-msg {
              position: relative;
              overflow: unset;

              p {
                position: absolute;
                background-color: $secondary-color;
                width: 200px;
                left: 0;
                bottom: -100%;
                font-size: 14px;
                font-weight: 500;
                padding: 6px;
                color: $white-color;
                pointer-events: none;
                opacity: 0;
                transition: 0.4s;
                z-index: 11;

                &::before {
                  content: "";
                  position: absolute;
                  width: 12px;
                  height: 12px;
                  background-color: $secondary-color;
                  top: -5px;
                  left: 20px;
                  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                }
              }

              &:hover {
                p {
                  bottom: -110%;
                  opacity: 1;
                  pointer-events: all;
                }
              }
            }
          }

          .child-menu {
            a {
              font-size: 15px;
              color: $text-color;
              text-transform: uppercase;
              text-decoration: none;
              position: relative;
              padding-left: 30px;

              &.activeSub {
                color: $primary-color;
                background: #f3b01b30;
              }

              &::before {
                content: "";
                width: 20px;
                height: 20px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                background-image: url("../../public/images/feather.svg");
                background-position: center;
                background-size: cover;
              }

              &.redirect-link {
                display: flex;
                align-items: center;

                svg {
                  font-size: 18px;
                  margin-left: 8px;
                  color: #999da1;
                }
              }
            }
          }
        }

        &:last-child {
          border-top: 1px solid #9f1d2173;
          padding: 12px 20px;
          display: flex;
          align-items: center;
          padding-top: 40px;
          justify-content: center;

          a {
            width: 35px;
            height: 35px;
            @include center();
            background-color: #9f1d212b;
            margin-right: 15px;
            color: $primary-color;
            font-size: 18px;
          }
        }
      }
    }
  }
}

section.banner {
  // background-image: url("../../public/images/homepage/banner/banner.webp");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 600px;
  // z-index: -1;

  @include mobile() {
    padding-top: 600px;
  }

  @include desktop() {
    padding-top: 640px;
  }

  @include mid-desktop() {
    padding-top: 700px;
  }

  .banner-content {
    position: absolute;
    display: flex;
    align-items: center;
    width: 94%;
    top: -300px;
    justify-content: center;
    flex-direction: column;

    @include small-tab() {
      width: 100%;
    }

    span {
      text-align: center;
      font-family: "Barlow Condensed", sans-serif;
      font-size: 30px;
      font-weight: 700;
      // background-color: #f3af1b9e;
      background-color: #0907027d;
      color: $white-color;
      padding: 0 20px;

      @include mobile() {
        font-size: 50px;
      }
    }

    a {
      margin-top: 50px;
      text-decoration: none;
      background-color: $secondary-color;
      color: $white-color;
      font-weight: 800;
      font-size: 15px;
      padding: 10px 20px;

      @include mobile() {
        font-size: 20px;
      }

      transition: 0.4s;
    }
  }
}

section.page-header {
  position: relative;
  height: 150px;
  // background-color: #f4f4f4;
  
  @include mobile() {
    height: 200px;
  }

  // @include desktop() {
  //   height: 450px;
  // }

  // @include mid-desktop() {
  //   height: 780px;
  // }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .page-content {
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: 110px;

    @include small-tab() {
      padding-top: 90px;
    }

    button {
      margin-top: 20px;
      border: 0;
      padding: 5px;
      color: $white-color;
      font-weight: 500;
      font-size: 12px;

      @include mobile() {
        margin-top: 30px;
        font-size: 18px;
      }

      @include big-tab() {
        padding: 8px 10px;
        margin-top: 40px;
        font-size: 20px;
      }
    }

    h2 {
      font-family: "Barlow Condensed", sans-serif;
      font-weight: 700;
      color: $white-color;
      padding: 5px 10px;
      font-size: 22px;
      position: relative;
      z-index: 10;

      @include mobile() {
        padding: 5px 20px;
        font-size: 30px;
      }

      @include small-tab() {
        font-size: 42px;
      }

      // @include big-tab() {
      //   font-size: 60px;
      // }

      // @include desktop() {
      //   font-size: 70px;
      // }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: -1;
        background-color: #f3b01b87;
      }
    }

    ._desc {
      margin-bottom: 0;
      margin: 0 10px;
      margin-top: 20px;
      background-color: $secondary-color;
      padding: 10px 20px;
      color: $white-color;
      text-transform: capitalize;
      text-align: center;
      font-size: 16px;

      @include big-tab() {
        font-size: 20px;
      }
    }
  }
}

footer {
  // border-top: 3px solid $secondary-color;
  position: relative;
  background-color: $white-color;
  z-index: 50;
  background-color: #f4f4f4;
}

section.welcome-iskcon {
  border-bottom: 6px solid $secondary-color;
  background-color: $white-color;
  position: relative;

  p {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    color: $text-color;

    @include mobile() {
      font-size: 20px;
    }
  }

  a {
    text-align: center;
    margin-top: 20px;
    font-weight: 700;
    padding: 10px 20px;
    text-decoration: none;
    color: $secondary-color;
    background-color: #f3af1b4c;
    font-size: 12px;
    border-radius: 5px;
    transition: 0.4s;

    @include small-mobile() {
      font-size: 16px;
    }

    @include mobile() {
      font-size: 18px;
    }

    transition: 0.4s;

    &:hover {
      background-color: $secondary-color;
      color: $white-color;
    }
  }
}

section.upcoming-immersions {
  background-color: #f3f3f3;
  position: relative;

  .immersion-wrap {
    border: 6px solid $secondary-color;
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    transition: 0.8s;

    @include small-tab() {
      margin-bottom: 0;
    }

    figure {
      width: 100%;
      transition: 0.4s;
      margin: 0;

      img {
        width: 100%;
        height: auto;
        transition: 0.4s;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #f3af1b38;
        top: 0;
        left: 0;
        z-index: 10;
      }
    }

    &:hover {
      figure {
        img {
          transform: scale(1.1);
        }
      }

      figure::before {
        z-index: 15;
      }

      //   border: 6px solid $primary-color;
    }

    .immersion-text {
      position: absolute;
      top: 58%;
      left: 0;
      z-index: 80;
      width: 100%;
      color: $white-color;
      background-color: #7c66439a;

      h4 {
        font-size: 35px;
        font-weight: 800;
        margin: 0;
        padding: 0;

        @include mobile() {
          font-size: 50px;
        }

        @include small-tab() {
          font-size: 35px;
        }

        @include big-tab() {
          font-size: 40px;
        }

        @include desktop() {
          font-size: 50px;
        }
      }

      p {
        svg {
          margin-left: 5px;
          margin-top: -12px;
          font-size: 12px;
        }

        font-size: 20px;
        margin: 0;
        padding: 0;

        @include mobile() {
          font-size: 40px;

          svg {
            margin-top: -20px;
            font-size: 18px;
          }
        }

        @include small-tab() {
          font-size: 20px;
        }

        @include big-tab() {
          font-size: 30px;
        }

        @include desktop() {
          font-size: 40px;
        }
      }
    }
  }

  .accordion-wrap {
    font-family: "Barlow Condensed", sans-serif;
    border: 6px solid $secondary-color;

    .accordion {
      border: none;
      background-color: $white-color;
      width: 100%;
      text-align: left;
      outline: none;
      transition: 0.4s;
      display: flex;
      align-items: center;
      cursor: pointer;

      button {
        width: 100%;
        font-weight: 700;
        font-size: 18px;
        color: #3a4149;
        padding: 15px;

        @include mobile() {
          font-size: 22px;
        }

        &:focus {
          box-shadow: unset;
          background-color: unset;
        }

        &::after {
          display: none;
        }

        &::before {
          content: "+";
          color: #fff;
          background-color: #3a4149;
          margin-right: 10px;
          font-weight: 600;
          font-size: 18px;
          padding: 0px 8px 3px 8px;
          transition: 0.4s;
        }

        &:not(.collapsed) {
          background-color: unset;
          box-shadow: unset;
          border-bottom: 1px solid #00000020;
          color: $secondary-color;

          &::before {
            content: "\2212";
            background-color: $secondary-color;
          }
        }
      }

      &:hover {
        button {
          color: $secondary-color;

          &::before {
            background-color: $secondary-color;
          }
        }
      }
    }

    .accordion-panel {
      ul {
        li {
          font-size: 16px;
          font-family: "Barlow", sans-serif;
          color: $text-color;
          margin-bottom: 12px;

          @include small-tab() {
            font-size: 18px;
          }

          &.title {
            &::before {
              display: none;
            }

            font-weight: 500;
            margin-top: 20px;
          }

          a {
            text-decoration: none;
            color: $text-color;
            transition: 0.4s;

            &:hover {
              color: $secondary-color;
            }

            &.redirect-link {
              display: flex;
              align-items: center;

              svg {
                margin-left: 6px;
                color: #a7afb7;
              }
            }
          }

          &.bullet {
            padding-left: 25px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              background-image: url("../../public/images/feather.svg");
              background-position: center;
              background-size: cover;
              left: 0;
              top: 6px;
            }
          }
        }
      }

      .dropdown-btn {
        .dropdown {
          button {
            border-radius: 0;
            padding: 7px 10px;
            border: 0;
            outline: 0;
            font-size: 18px;
            box-shadow: unset !important;
            position: relative;
            z-index: 10;
            background-color: transparent;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: $secondary-color;
              z-index: -1;
              transition: 0.4s;
            }

            &:hover {
              &::before {
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }
  }

  &.right-align {
    .accordion-wrap {
      .accordion {
        button {
          position: relative;

          &::before {
            right: 0;
            position: absolute;
            padding: 0;
            font-size: 24px;
            width: 30px;
            height: 30px;
            @include center();
            color: #3a414936;
            background-color: transparent;
          }
        }
      }
    }
  }

  &._csr-page {
    .immersion-wrap {
      .immersion-text {
        top: 70%;

        h4 {
          text-align: center;
          font-size: 30px;

          @include mobile() {
            font-size: 26px;
          }

          @include small-tab() {
            font-size: 35px;
          }

          @include big-tab() {
            font-size: 30px;
          }

          @include desktop() {
            font-size: 35px;
          }
        }
      }
    }
  }
}

.csr-new.banner {
  background-color: $secondary-bg-color;
  font-family: "Barlow Condensed", sans-serif;
  position: relative;

  .banner-lg-img {
    position: absolute;
    top: 0;
    right: 0;

    @include too-small-mobile() {
      padding-top: 8rem;
    }

    @include small-tab() {
      padding-top: 9rem;
    }

    @include big-tab() {
      padding-top: 19rem;
      margin-top: 5rem;
      width: 55%;
    }

    @include mid-desktop() {
      width: 55%;
    }

    @include large-desktop() {
      width: 50%;
    }
  }

  @include too-small-mobile() {
    padding-top: 8rem;
  }

  @include small-tab() {
    padding-top: 9rem;
  }

  @include small-desktop() {
    padding-top: 17rem;
  }

  p {
    font-size: 19px;
    color: $text-color;

    @include small-desktop() {
      margin-top: 15px;
      font-size: 21px;
    }
  }

  .head-3 {
    color: $primary-color;
    font-weight: 500;

    @include too-small-mobile() {
      font-size: 19.5px;
    }

    @include small-tab() {
      font-size: 22.5px;
    }

    @include small-desktop() {
      font-weight: 400;
      font-size: 31px;
    }
  }

  .dual-heading h2 {
    font-weight: 900;

    @include too-small-mobile() {
      font-size: 35.5px;
    }

    @include small-tab() {
      font-size: 40.5px;
    }

    @include small-desktop() {
      font-size: 50.5px;
    }
  }

  .banner-img {
    @include too-small-mobile() {
      width: 85%;
    }

    @include mobile() {
      width: 100%;
    }

    @include small-desktop() {
      visibility: hidden;
    }

    @include desktop() {
      margin-left: 20px;
    }
  }
}

.csr-new.annadaan-wrap {
  font-family: "Barlow Condensed", sans-serif;
  padding: 0;
  background-color: $secondary-bg-color;

  .annadaan-card {
    padding: 10px 30px;
    border: 2px solid $secondary-color;
    min-height: 65px;
    @include center();

    @include too-small-mobile() {
      width: 100%;
      gap: 10px;
      margin-top: 10px;
    }

    @include small-tab() {
      justify-content: space-around;
      width: 250px;
      gap: 0;
      margin-top: 0;
    }

    img {
      pointer-events: none;
      user-select: none;
    }

    span {
      font-size: 23.5px;
      font-weight: 500;
      color: $primary-color;
    }
  }
}

.csr-new.annadaan-table {
  background-color: $secondary-bg-color;

  font-family: "Barlow Condensed", sans-serif;

  @include small-tab() {
    margin-top: 9.5rem;
  }

  @include small-desktop() {
    margin-top: 0;
  }

  .csr-wrap {
    .csr-table-wrap {
      border: 5px solid $secondary-color;
      padding: 5px 5px;
      margin: auto;

      @include small-desktop() {
        padding: 8px 8px;
      }
    }

    h4 {
      text-align: center;
      color: white;
      margin-bottom: 0;
      // font-size: 18px;
      font-weight: 600;
      // @include mobile() {
      //   font-size: 20px;
      // }
    }

    h5 {
      text-align: center;
      color: $dark-text-color;
      font-weight: 600;
      // font-size: 16px;
      margin-bottom: 0;
      // @include mobile() {
      //   font-size: 18px;
      // }
    }

    .tab-col {
      border-bottom: 1px solid $primary-color;
      border-left: 1px solid $primary-color;
      border-right: 1px solid $primary-color;
      padding: 10px;
      height: 100%;
      // background-color: $white-color;
      @include center();

      &.left {
        justify-content: flex-start;

        p,
        h5 {
          margin-left: 16px;

          @include small-desktop() {
            margin-left: 20px;
          }
        }
      }
    }

    ._header {
      background-color: $primary-color;
      padding: 15px 0;
      border: 1px solid $primary-color;
      border-bottom: 0;
    }

    p {
      margin-bottom: 0;
      text-align: center;
      color: $text-color;
      font-size: 19px;
    }
  }

  .head-title {
    h4 {
      font-size: 21.5px;

      @include big-tab() {
        font-size: 23.5px;
      }

      h5 {
        font-size: 20px;

        @include big-tab() {
          font-size: 22px;
        }
      }
    }
  }
}

.csr-new.annadaan {
  background-color: $secondary-bg-color;
  font-family: "Barlow Condensed", sans-serif;
  scroll-margin: 60px;

  .heading h2 {
    font-size: 45.5px;
    font-weight: 900;
    padding-bottom: 60px;

    @include too-small-mobile() {
      font-size: 35.5px;
    }

    @include small-tab() {
      font-size: 40.5px;
    }

    @include big-tab() {
      padding-bottom: 80px;
    }

    @include small-desktop() {
      font-size: 46px;
    }
  }

  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 35.5px;
    font-weight: 700;

    @include small-tab() {
      font-size: 45.5px;
    }

    @include small-desktop() {
      font-size: 50.5px;
    }
  }

  .annadaan-content-wrap {
    @include small-tab() {
      position: relative;
    }

    figure {
      position: relative;
      width: 70%;

      @include too-small-mobile() {
        width: 90%;
        margin-left: 5%;
      }

      @include small-tab() {
        width: 90%;
        margin-left: 5%;
      }

      @include small-desktop() {
        width: 60%;
        margin-left: 0%;
      }

      @include mid-desktop() {
        margin-left: 5%;
      }

      img {
        width: 100%;
      }

      span {
        letter-spacing: 3px;
        position: absolute;
        font-weight: 500;
        height: 75px;
        width: 185px;
        top: 0;
        color: white;
        right: 0;

        @include small-desktop() {
          right: auto;
        }

        background-color: #f3b01b;
        font-size: 22px;
        text-transform: uppercase;
        text-align: center;
        padding-top: 20px;
      }
    }

    .annadaan-content {
      color: white;
      padding: 30px 50px;
      font-family: "Barlow", sans-serif;

      @include small-tab() {
        position: absolute;
        width: 85%;
        top: 70%;
        right: 0;
        left: 0;
      }

      @include small-desktop() {
        width: 60%;
        top: 20%;
        right: 0%;
        left: auto;
      }

      @include desktop() {
        width: 45%;
        top: 14%;
        right: 5%;
        left: auto;
      }

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        right: 0;
        opacity: 90%;
        background: $secondary-color;
        padding: 30px 40px;
        z-index: 1;
      }

      & > * {
        z-index: 100;
        position: relative;
      }

      h1 {
        font-family: "Barlow condensed", "sans-serif";

        @include small-desktop() {
          font-size: 25.5px;
        }

        @include desktop() {
          font-size: 38.5px;
        }
      }

      li {
        font-family: "Barlow condensed", "sans-serif";
        color: $primary-color;
        font-weight: 600;

        @include small-desktop() {
          font-size: 18.5px;
        }

        @include desktop() {
          font-size: 21.5px;
        }

        &::marker {
          font-size: 20px;

          @include large-desktop() {
            font-size: 30.5px;
          }
        }

        span {
          transform: translateY(-3px);
          font-size: 20.5px;

          @include small-desktop() {
            font-size: 24.5px;
          }
        }
      }
    }

    .annadaan-content-sm {
      color: white;
      padding: 20px 30px;
      position: relative;
      margin-top: -48px;
      width: 94%;
      margin-left: 3%;

      &::after {
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        right: 0;
        opacity: 90%;
        background: $secondary-color;
        z-index: 1;
      }

      & > * {
        z-index: 100;
        position: relative;
      }

      li {
        color: $primary-color;
        font-family: "Barlow condensed", "sans-serif";
        color: $primary-color;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }
}

.csr-new.partner-with-us {
  font-family: "Barlow Condensed", sans-serif;

  .partner-feature-cards-wrap {
    margin: 40px 0 40px 0;

    @include desktop() {
      width: 80%;
      margin: 60px 0 60px 10%;
    }

    .partner-feature-card {
      border: 2px solid $secondary-color;
      padding: 3px 6px;
      // background-color: white;
      color: $primary-color;
      font-size: 20.5px;
      font-weight: 600;
      height: 100%;

      p {
        text-align: center;
        padding: 5px;
        margin-bottom: 0.5%;
      }
    }
  }

  .heading h2 {
    font-size: 45px;
    font-weight: 900;

    @include too-small-mobile() {
      font-size: 35px;
      // margin: 25px;
    }

    @include small-tab() {
      font-size: 40px;
      // margin: 35px ;
    }

    @include small-desktop() {
      font-size: 45px;
      margin-bottom: 50px;
    }
  }

  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 35.5px;
    font-weight: 700;

    @include small-tab() {
      font-size: 45.5px;
    }

    @include small-desktop() {
      font-size: 50.5px;
    }
  }

  .csr-partners-slider {
    background-color: white;

    .partner-slide {
      // border: 4px solid $secondary-color;
      box-shadow: 5px 5px 30px 0px rgba(217, 217, 217, 0.3);
      @include center();
      // padding: 10px;
      background-color: $white-color;

      figure {
        width: 100%;
        height: 100px;
        @include center();

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.csr-new.education-need {
  font-family: "Barlow Condensed", sans-serif;

  figure {
    width: 100%;
  }

  .heading p {
    line-height: 25px;

    @include small-desktop() {
      line-height: 27px;
    }
  }
}

.csr-new.future-plan {
  font-size: 19px;
  font-family: "Barlow Condensed", sans-serif;
}

.csr-new.green-init {
  background-color: transparent;
  font-family: "Barlow Condensed", sans-serif;
  scroll-margin: 30px;

  .splide__pagination__page {
    background: #ccc;
    border: 0;
    border-radius: 0;
    display: inline-block;
    height: 10px;
    margin: 5px;
    margin-left: 5px;

    @include mobile() {
      margin-left: 10px;
    }

    opacity: 0.7;
    padding: 0;
    position: relative;
    transition: transform 0.2s linear;
    width: 10px;

    &.is-active {
      transform: scale(1);
      background-color: $white-color;
      opacity: 1;
    }
  }

  .splide__pagination {
    @include mobile() {
      transform: translateY(-22px);
    }
  }

  .accordion-button {
    font-size: 21.5px;
    color: $primary-color;
    font-weight: 500;
    // border: 1px solid $secondary-color;

    &[aria-expanded="true"] {
      background-color: $secondary-color;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordion-body {
    border: 1px solid $secondary-color;
  }

  .green-init-acc-card {
    h4 {
      color: $primary-color;
      font-size: 22.5px;
      font-weight: 600;
    }

    figure {
      img {
        width: 100%;
        height: 200px;
      }
    }
  }

  ._main {
    h2 {
      padding-top: 8px;
      padding-bottom: 8px;
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        width: 60%;
        height: 4px;
        background-color: $primary-color !important;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20%;
      }
    }
  }

  p {
    font-size: 19px;
    color: $text-color;

    @include small-tab() {
      font-size: 20px;
    }

    @include small-desktop() {
      line-height: 30px;
    }
  }

  .heading {
    h4 {
      color: $primary-color;
      font-size: 25px;
      font-weight: 500;
      margin-top: 20px;
      text-transform: uppercase;

      @include small-tab() {
        font-size: 31px;
      }
    }

    .head-1 {
      font-family: "Barlow Condensed", sans-serif;
      color: $secondary-color;
      font-size: 36px;
      font-weight: 700;

      @include small-tab() {
        font-size: 45.5px;
      }

      @include small-desktop() {
        font-size: 50.5px;
      }
    }
  }

  .csr-green-init-tab-wrap {
    .csr-green-init-tabs {
      justify-content: center;
      border: 0;
      flex-direction: column;

      @include small-tab() {
        flex-direction: row;
      }

      .nav-item {
        border: 0;
        margin-bottom: 0;

        &:first-child {
          position: relative;

          &::after {
            content: "";

            @include small-tab() {
              position: absolute;
              width: 1px;
              margin-top: 5%;
              height: 60%;
              background-color: #a3a3a3;
              top: 0;
              right: 0;
            }
          }
        }

        &:last-child {
          position: relative;

          &::after {
            content: "";

            @include small-tab() {
              position: absolute;
              width: 1px;
              margin-top: 5%;
              height: 60%;
              background-color: #a3a3a3;
              top: 0;
              left: 0;
            }
          }
        }

        button {
          padding: 10px;
          border-radius: 0;
          border: 0;
          outline: none;
          color: $dark-text-color;
          font-weight: 600;
          // border-bottom: 2px solid #e1e1e1;
          position: relative;
          width: 100%;

          @include small-tab() {
            width: auto;
            padding: 15px 25px;
            font-weight: 700;
            color: gray;
            margin-bottom: -5px;
          }

          :first-child {
            background-color: red;
          }

          &.active {
            color: $primary-color;
            background-color: $secondary-color;
          }
        }
      }
    }

    .tab-content {
      // padding-top: 30px;
      // padding: 15px;
      // @include small-tab(){
      // padding: 20px;
      // }
      border: 5px solid $secondary-color;

      figure {
        padding: 10px;
        width: 100%;

        @include small-tab() {
          // width: 50%;
        }

        @include big-tab() {
          width: 100%;
        }

        img {
          width: 100%;
        }

        &._first {
          @include desktop() {
            height: 450px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .tab-inner-content {
        h4 {
          color: $primary-color;
          font-weight: 600;
          margin-bottom: 15px;
          font-size: 20px;

          @include small-tab() {
            font-size: 32px;
          }
        }

        p {
          font-size: 18px;
          color: $text-color;

          @include small-tab() {
            font-size: 20px;
          }
        }
      }
    }
  }
}

.csr-new.value-education {
  font-family: "Barlow Condensed", sans-serif;
  scroll-margin: 110px;

  ._main {
    h2 {
      padding-top: 8px;
      padding-bottom: 8px;
      position: relative;
      display: inline-block;

      &::before {
        content: "";
        position: absolute;
        width: 60%;
        height: 4px;
        background-color: $primary-color !important;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20%;
      }
    }
  }

  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 36px;
    font-weight: 700;

    @include small-tab() {
      font-size: 45px;
    }

    @include small-desktop() {
      font-size: 50px;
    }
  }

  .heading h4 {
    font-weight: 600;
    font-size: 24.5px;

    @include big-tab() {
      font-size: 31px;
    }
  }

  .value-education-card {
    background-color: $secondary-color;
    padding: 5px;

    @include small-tab() {
      padding: 10px;
    }

    color: $primary-color;
    display: flex;
    margin: 0 10px;

    h4 {
      font-weight: 600;
      font-size: 25.5px;

      @include small-tab() {
        font-weight: 600;
        font-size: 31px;
      }
    }

    figure {
      width: 200px;
      margin-bottom: -1px;

      img {
        width: 100%;
        padding-right: 10px;
      }
    }
  }
}

ul.styled-list {
  list-style: none;

  li::before {
    content: "\2022";
    color: $primary-color;
    font-weight: 900;
    display: inline-block;
    width: 15px;
    margin-left: 5px;
  }

  &.text-yellow {
    li {
      color: $secondary-color;
      font-size: 22.5px;
      font-weight: 800;
    }
  }
}

section.csr-new.only-content {
  font-family: "Barlow Condensed", sans-serif !important;

  li {
    font-size: 19px;

    @include big-tab() {
      font-size: 22.5px;
    }

    font-weight: 500;
    color: $text-color;
  }

  small {
    font-size: 18.5px;

    @include big-tab() {
      font-size: 20px;
    }

    font-style: normal;
  }
}

.csr-new.what-we-do {
  @include big-tab() {
    display: flex;
  }

  .only-content {
    background-color: #99161a;

    @include small-tab() {
      padding: 2rem 4rem;
    }

    @include small-desktop() {
      padding: 3rem 5rem;
    }

    &.dark {
      background-color: #77181b;
    }

    .notes-wrap {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      small {
        color: $primary-color;
        font-style: italic;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }

    p {
      color: white !important;
      font-size: 16px;

      @include big-tab() {
        font-size: 19px;
      }
    }
  }

  .what-we-do-content-wrap {
    position: relative;
    width: 100%;

    .what-we-do-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .what-we-do-card {
        @include big-tab() {
          padding: 3rem 1rem;
        }

        @include large-desktop() {
          padding: 3rem 5rem;
        }

        p {
          color: white !important;
          font-size: 16.5px;

          @include big-tab() {
            font-size: 19.5px;
          }
        }

        &.light {
          padding-left: 25px;
        }
      }
    }
  }
}

.csr-curriculum {
}

.csr-new.goal-cost {
  font-family: "Barlow Condensed", sans-serif !important;

  .goal-card {
    background-color: #fdf0d4;

    h4 {
      color: $primary-color;
      font-weight: 800;
      font-size: 26.5px;

      @include small-desktop() {
        font-size: 31px;
      }
    }

    p {
      font-weight: 500;
      font-size: 19.5px;

      @include small-desktop() {
        font-size: 22.5px;
      }
    }
  }
}

.testimonial-popup-card {
  background-color: $white-color;
  padding: 30px 45px;
  min-height: 570px;

  &.static {
    height: 100%;

    p {
      font-size: 18px;
      color: $text-color;
    }

    figure {
      background-color: #e3e8eb;

      @include small-tab() {
        width: 100%;
        height: auto;
      }

      &._first {
        background-color: #eff1eb;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: scale(0.95);
      }
    }
  }

  figure {
    width: 140px;
    height: 140px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h5 {
    font-size: 22px;
    color: $primary-color;
    // margin-bottom: 10px;
    font-weight: 500;
    // font-style: italic;
  }

  h6 {
    margin-bottom: 15px;
    font-weight: 500;
    color: $text-color;
    font-size: 18px;
  }

  p {
    color: $text-color;
    font-size: 18px;
  }
}

.csr-new.testimonial {
  background-color: #fffbf2;

  &._students {
    background-color: #f3f3f3;
  }

  .rounded-img {
    background-color: #e3e8eb;

    @include small-tab() {
      width: 100%;
    }

    @include big-tab() {
      width: 40% !important;
    }

    @include desktop() {
      width: 100%;
    }
  }

  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 30.5px;
    font-weight: 700;

    @include small-tab() {
      font-size: 40.5px;
    }

    @include small-desktop() {
      font-size: 45.5px;
    }
  }

  .read-more-btn {
    background-color: $secondary-color;
    color: $white-color;
    padding: 10px 15px;
    font-size: 18.5px;
    border: none;
  }

  .testimonial-card-wrap {
    @include center();
    gap: 100px;
  }

  .testimonial-card {
    background-color: $white-color;
    padding: 30px 45px;

    // max-width: 470px;
    @include small-desktop() {
      width: 92%;
    }

    @include mid-desktop() {
      width: 80%;
    }

    min-height: 570px;

    // max-height: 550px;
    &.static {
      height: 100%;

      p {
        font-size: 18.5px;
        color: $text-color;
      }

      figure {
        background-color: #e3e8eb;

        @include small-tab() {
          width: 100%;
          height: auto;
        }

        &._first {
          background-color: #eff1eb;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          transform: scale(0.95);
        }
      }
    }

    figure {
      width: 140px;
      height: 140px;
      overflow: hidden;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h5 {
      font-size: 22.5px;
      color: $primary-color;
      // margin-bottom: 10px;
      font-weight: 500;
      // font-style: italic;
    }

    h6 {
      margin-bottom: 15px;
      font-weight: 500;
      color: $text-color;
      font-size: 18.5px;
    }

    p {
      color: $text-color;
      font-size: 18px;
    }
  }

  .csr-test-wrap {
    .testimonial-slide {
      background-color: $white-color;
      padding: 20px;

      // max-width: 470px;
      &.static {
        height: 100%;

        p {
          font-size: 18.5px;
          color: $text-color;
        }

        figure {
          background-color: #e3e8eb;

          @include small-tab() {
            width: 100%;
            height: auto;
          }

          @include big-tab() {
            width: 35% !important;
          }

          @include desktop() {
            width: 100%;
            height: auto;
          }

          &._first {
            background-color: #eff1eb;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: scale(0.95);
          }
        }
      }

      figure {
        width: 140px;
        height: 140px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-size: 22.5px;
        color: $primary-color;
        // margin-bottom: 10px;
        font-weight: 500;
        // font-style: italic;
      }

      h6 {
        margin-bottom: 15px;
        font-weight: 500;
        color: $text-color;
        font-size: 18.5px;
      }

      p {
        color: $text-color;
        font-size: 18.5px;
      }
    }

    .splide__pagination {
      bottom: -40px;

      li {
        button {
          width: 40px;
          height: 4px;
          background-color: #9f1d2175;
          transform: unset;
          border-radius: 0;
          margin: 0 6px;

          &.is-active {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.csr-new.cta {
  @include too-small-mobile() {
    background-position-x: 0;
  }
}

.csr-new.csr-testimonial._students {
  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 30.5px;
    font-weight: 700;

    @include small-tab() {
      font-size: 40.5px;
    }

    @include small-desktop() {
      font-size: 46px;
    }
  }
}

.csr-new.csr-curriculum {
  .reach-title {
    color: $secondary-color;
    font-weight: 900;

    @include too-small-mobile() {
      font-size: 28px;
    }

    @include small-tab() {
      font-size: 32px;
    }

    @include big-tab() {
      margin-left: 4.7%;
    }

    @include small-desktop() {
      font-size: 38px;
    }
  }

  background-color: #f4f4f4;

  .reach-content {
    h2 {
      color: $primary-color;
      font-weight: 900;
      font-size: 31px;

      @include small-desktop() {
        font-size: 40px;
      }
    }

    h3 {
      color: $primary-color;
      font-size: 23px;
      font-weight: 700;

      @include small-desktop() {
        font-size: 27px;
      }
    }

    .bottom-count {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        width: 92%;
        height: 4px;
        top: 10%;
        right: 2%;
        background-color: $secondary-color;
      }
    }
  }

  .head-1 {
    font-family: "Barlow Condensed", sans-serif;
    color: $secondary-color;
    font-size: 30.5px;
    font-weight: 700;

    @include small-tab() {
      font-size: 40.5px;
    }

    @include small-desktop() {
      font-size: 45.5px;
    }
  }

  ul {
    li {
      font-size: 22px;
      font-weight: 900;
      margin-left: 16px;
      color: $secondary-color;

      @include small-tab() {
        font-size: 24px;
      }

      @include small-desktop() {
        font-size: 28px;
      }

      .pointer {
        position: relative;
        margin-right: 10px;

        &::before {
          content: "";
          background-color: $primary-color;
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 40%;
          right: 0;
        }
      }
    }
  }
}

section.ondemand-courses {
  background-color: #f3f3f3;
  border-top: 6px solid $secondary-color;
  position: relative;

  .container {
    position: relative;
    z-index: 15;
  }

  .card-wrap {
    .card-bg {
      @include small-tab() {
        margin: 0;
      }

      position: relative;
      height: 380px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        figure {
          img {
            transform: scale(1.1);
          }
        }

        .card-overlay {
          .card-text {
            h6 {
              top: -10px;
            }

            p {
              bottom: -10px;
            }

            .card-btn {
              opacity: 1;
              transform: translateY(-10px);
            }
          }
        }
      }

      figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          transition: 0.8s;
        }
      }

      .card-overlay {
        position: relative;
        z-index: 10;
        height: 100%;
        background: rgb(23, 19, 16);
        background: linear-gradient(0deg, #1713109e 0%, #ffffff00 100%);

        .card-text {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          bottom: 0px;
          text-align: center;

          p {
            font-family: "Barlow Condensed", sans-serif;
            color: $secondary-color;
            font-size: 20px;
            position: relative;
            bottom: 0;
            transition: 0.4s;
          }

          h6 {
            font-size: 16px;
            color: $white-color;
            margin-top: 20px;
            transition: 0.4s;
            position: relative;
            top: 0;
          }

          .card-btn {
            opacity: 0;
            transition: 0.4s;
            font-size: 16px;
          }
        }
      }
    }
  }

  .course-open {
    margin-top: 50px;

    a {
      margin-top: 50px;
    }
  }

  .arrow-wrap {
    top: 48%;
  }
}

section.campaign-slider {
  background-color: #f3f3f3;
  border-top: 6px solid $secondary-color;
  position: relative;

  .container {
    position: relative;
    z-index: 15;
  }

  .card-wrap {
    .card-bg {
      @include small-tab() {
        margin: 0;
      }

      position: relative;
      height: 380px;
      padding: 10px;
      cursor: pointer;

      &:hover {
        figure {
          img {
            transform: scale(1.1);
          }
        }

        .card-overlay {
          .card-text {
            h6 {
              top: -10px;
            }

            p {
              bottom: -10px;
            }

            .card-btn {
              opacity: 1;
              transform: translateY(-10px);
            }
          }
        }
      }

      figure {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 0;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          transition: 0.8s;
        }
      }

      .card-overlay {
        position: relative;
        z-index: 10;
        height: 100%;
        background: rgb(23, 19, 16);
        background: linear-gradient(0deg, #1713109e 0%, #ffffff00 100%);

        .card-text {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          bottom: 0px;
          text-align: center;

          p {
            font-family: "Barlow Condensed", sans-serif;
            color: $secondary-color;
            font-size: 20px;
            position: relative;
            bottom: 0;
            transition: 0.4s;
          }

          h6 {
            font-size: 16px;
            color: $white-color;
            margin-top: 20px;
            transition: 0.4s;
            position: relative;
            top: 0;
          }

          .card-btn {
            opacity: 0;
            transition: 0.4s;
            font-size: 16px;
          }
        }
      }
    }
  }

  .course-open {
    margin-top: 50px;

    a {
      margin-top: 50px;
    }
  }

  .arrow-wrap {
    border: 56%;
  }
}

section.yt-links-sec {
  .video-wrap {
    .video-holder {
      border: 6px solid $secondary-color;
      margin-bottom: 80px;

      @include small-tab() {
        margin-bottom: 0;
      }

      &._csr-edu {
        margin-bottom: 80px;
      }

      transition: 0.4s;

      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100%;
        height: 100%;

        figure {
          position: relative;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .thumbnil {
          &::before {
            position: absolute;
            content: "";
            transition: 0.4s;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #3a414944;
          }
        }

        .reason {
          position: absolute;
          width: 80%;
          text-align: center;
          background-color: #fff;
          display: flex;

          padding: 10px 20px;
          align-items: center;
          justify-self: center;
          top: 85%;
          transition: 0.4s;
          display: flex;
          justify-self: center;
          padding: 10px;

          @include small-mobile() {
            padding: 20px 5px;
            margin: 0 40px;
          }

          @include mobile() {
            margin: 0 40px;
          }

          @include small-tab() {
            margin: 0 20px;
            padding: 15px 12px;
          }

          p {
            width: 100%;
            text-align: center;
            color: $secondary-color;
            font-size: 18px;
            margin-bottom: 0;

            @include small-mobile() {
              font-size: 20px;
            }

            @include small-tab() {
              font-size: 18px;
            }

            @include big-tab() {
              font-size: 18px;
            }

            @include desktop() {
              font-size: 20px;
            }
          }
        }

        .yt-logo {
          z-index: 50;
          position: absolute;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;

          color: red;

          figure {
            width: 80px;
            height: 80px;

            svg {
              font-size: 40px;
            }
          }
        }
      }

      &:hover {
        .reason {
          box-shadow: 5px 10px 18px #b1b0b069;
        }

        figure::before {
          background-color: #3a414976;
        }
      }
    }
  }

  .sec-btn {
    background-color: $secondary-color;
    color: $white-color;
    padding: 5px 25px;
    text-decoration: none;
    margin-top: 50px;
    font-size: 20px;
    width: 200px;
    text-align: center;
  }
}

section.donate {
  background-color: #ffff;
  z-index: 1100;
  position: relative;
}

section.donate-new {
  background-color: $white-color;
  position: relative;

  .accordion_wrap {
    .accordion {
      .accordion-item {
        border-radius: 0;
        margin-bottom: 10px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        h2 {
          button {
            border: 1px solid red;
            border: 0;
            outline: 0;
            transition: 0.4s;

            &::after {
              display: none;
            }

            &:focus {
              box-shadow: unset;
            }

            &:not(.collapsed) {
              box-shadow: unset;
              border-bottom: 1px solid #ddd;

              span {
                color: $secondary-color;

                &.h-icon {
                  color: $secondary-color;
                }
              }

              &:hover {
                span {
                  color: $white-color;
                }
              }
            }

            background-color: $white-color;

            span {
              color: #1e1e1e;
              text-transform: uppercase;
              font-size: 18px;
              font-weight: 500;
              transition: 0.4s;

              &.h-icon {
                color: #1e1e1e;
                margin-right: 10px;

                svg {
                  font-size: 24px;
                }
              }
            }

            &:hover {
              background-color: $secondary-color;

              span {
                color: $white-color;

                svg {
                  color: $white-color;
                }
              }
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            a {
              border: 1px solid $secondary-color;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              text-align: center;
              color: $text-color;
              padding: 10px;
              padding-right: 20px;
              position: relative;
              transition: 0.4s;
              width: 100%;
              height: 100%;

              &:hover,
              .active {
                background-color: $secondary-color;
                color: $white-color;
                padding-right: 25px;

                span {
                  &.icon {
                    right: 10px;
                    opacity: 1;
                  }
                }

                .camp-tooltip {
                  pointer-events: unset;
                  top: -160px;
                  opacity: 1;
                }
              }

              span {
                &.icon {
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 0;
                  transition: 0.4s;
                  opacity: 0;
                  color: $white-color;
                }
              }

              .camp-tooltip {
                position: absolute;
                width: 100%;
                top: -170px;
                padding: 10px;
                border-radius: 4px;
                background-color: #000;
                left: 0;
                z-index: 10;
                pointer-events: none;
                opacity: 0;
                transition: 0.4s;

                &::before {
                  content: "";
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  left: 50%;
                  bottom: -10px;
                  transform: translateX(-50%);
                  clip-path: polygon(50% 100%, 0 0, 100% 0);
                  background-color: #000;
                }
              }
            }
          }
        }
      }
    }
  }
}

section.welcome-festival {
  // border-bottom: 6px solid $secondary-color;
  background-color: $white-color;
  position: relative;

  p {
    font-size: 18px;
    text-align: center;
    margin-top: 20px;
    color: $text-color;
    position: relative;

    @include mobile() {
      font-size: 20px;
    }
  }
}

section.content {
  background-color: #f4f4f4;
  position: relative;

  .content-card {
    height: 100%;
    -webkit-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 27px 0px rgba(0, 0, 0, 0.1);

    background-color: $white-color;
    display: flex;

    flex-direction: column;
    text-align: center;
    // justify-content: space-between;

    figure {
      position: relative;
      max-height: 388px;
      overflow: hidden;

      width: 100%;
      margin-bottom: 0;

      .btn-flex {
        width: 100%;
        position: absolute;
        bottom: 20px;
        display: flex;
        justify-content: center;

        .custom-btn-cls {
          opacity: 1;

          @include mobile() {
            opacity: 0;
          }
        }

        a,
        button {
          margin: 0 8px;
          font-size: 10px;
          padding: 6px 8px;

          @include small-mobile() {
            font-size: 12px;
            margin-right: 10px;
          }

          @include mobile() {
            font-size: 15px;
            margin-right: 15px;
          }

          @include small-tab() {
            margin-right: 0;
            font-size: 13px;
            padding: 5px 10px;
          }

          @include big-tab() {
            margin-right: 20px;
            font-size: 16px;
          }

          @include desktop() {
            font-size: 18px;
            padding: 7px 15px;
          }

          color: $white-color;
          background-color: $secondary-color;
          border: 0;
          // opacity: 0;
          transition: 0.8s;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        height: aut0;
      }

      &.card-adjust {
        img {
          object-position: top;
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, #00000000 0%, #00000029 100%);
        transform: translateY(100%);
        transition: 0.4s;
      }
    }

    .content-info {
      // height: 100%;
      padding: 10px 15px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mobile() {
        padding: 20px;
      }

      @include big-tab() {
        padding: 25px;
      }

      a {
        text-decoration: none;

        h2 {
          color: #3a4149;
          font-family: "Barlow Condensed", sans-serif;
          margin: 0;
          padding-top: 10px;
          text-transform: capitalize;
        }
      }

      span {
        margin: 10px;
        width: 50px;
        border-bottom: 3px solid $secondary-color;
      }

      h5 {
        color: $primary-color;

        margin-bottom: 15px;

        @include mobile() {
          margin-bottom: 20px;
        }
      }

      p {
        font-size: 16px;

        color: $text-color;

        @include mobile() {
          font-size: 18px;
          line-height: 1.5rem;
        }
      }
    }

    &:hover {
      figure {
        .btn-flex {
          a,
          button {
            opacity: 1;
          }
        }
      }

      figure::before {
        transform: translateY(0);
      }
    }
  }
}

section.donation-form-sec {
  z-index: 50;
  position: relative;
  background-color: $white-color;

  .heading {
    text-align: center;

    h2 {
      font-size: 30px;
      font-weight: 600;
      font-family: "Barlow Condensed", sans-serif;
      color: $secondary-color;
      z-index: 50;
    }
  }

  .donate-note {
    color: $primary-color;
    text-align: center;

    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 12px;

      @include mobile() {
        font-size: 15px;
      }
    }
  }

  .form-part {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    position: relative;

    label {
      color: $secondary-color;
      font-weight: 500;
      padding-bottom: 10px;
    }

    select {
      border-radius: 3px;
      color: $text-color;
      border: 1px solid #7c828b38;
      padding: 8px 5px;

      &:focus-visible {
        outline: 0;
        border: 1px solid $secondary-color;
      }

      // font-weight: 500;
    }

    textArea,
    input {
      border-radius: 3px;
      color: $text-color;
      border: 1px solid #7c828b38;
      padding: 7.5px 10px;
      // font-weight: 500;
      height: 42px;

      &::placeholder {
        color: #7c848d36;
        font-weight: 400;
      }

      &::-webkit-placeholder {
        color: #7c848d36;
        font-weight: 400;
      }

      &:focus-visible {
        outline: 0;
        border: 1px solid $secondary-color;
      }
    }

    .notes-wrap {
      border: 1px solid #f3b01b52;
      border-radius: 3px;
      padding: 4px 5px;
      background-color: #f3b01b17;

      @include small-tab() {
        margin-top: 30px;
      }

      p {
        font-size: 14px;
        margin-bottom: 0;
        color: $text-color;

        span {
          font-weight: 600;
        }
      }
    }

    a.donation-link {
      font-size: 13px;
      color: $secondary-color;
      text-align: right;
      position: absolute;
      bottom: -22px;
      right: 0;
      text-decoration: underline;
    }

    &.memory_field {
      textarea {
        border: 1px solid $secondary-color;
        height: unset;
      }
    }

    .shop-count {
      border: 1px solid $secondary-color;
      display: flex;
      align-items: center;
      padding: 2px;
      justify-content: space-between;

      .amount {
        display: flex;
        align-items: center;

        img {
          width: 45px;
          height: 45px;
          object-fit: cover;
        }

        span {
          margin-left: 20px;
          font-weight: 600;
          color: $text-color;
          font-size: 18px;
        }
      }

      .count-wrap {
        .count {
          display: flex;
          align-items: center;

          button {
            border: 1px solid #7c828b38;
            background-color: #f3b01b1a;
            width: 30px;
            height: 30px;
            transition: 0.4s;
            border-radius: 0;

            &:hover {
              background-color: #f3af1b4d;
            }
          }

          span.showCount {
            width: 40px;
            height: 30px;
            border: 1px solid #7c828b38;
            text-align: center;
            border-left: 0;
            border-right: 0;
            padding-top: 2px;
          }
        }
      }
    }
  }

  .form-wrap {
    .check-wrap {
      cursor: pointer;

      display: flex;
      align-items: center;

      .checkbox-text {
        color: $secondary-color;
        font-weight: 500;
      }

      .checkmark {
        position: relative;
        color: $white-color;
        height: 16px;
        width: 16px;
        background-color: $white-color;
        border: 1px solid $secondary-color;
        margin-right: 5px;

        svg {
          position: absolute;
          font-size: 11px;
          top: 18%;
          left: 10%;
        }
      }

      .og-checkbox:checked ~ .checkmark {
        background-color: $secondary-color;
      }
    }

    // .check-wrap input:checked ~ .checkmark {
    //     background-color:$secondary-color;
    //   }
    //   .check-wrap input:checked ~ .checkmark:after {
    //     display: block;
    //   }

    form {
      border: 6px solid $secondary-color;
      padding: 15px;

      @include mobile() {
        padding: 30px;
      }

      .donate-img {
        height: 100%;
        display: flex;
        align-items: center;

        figure {
          overflow: hidden;
          margin-bottom: 20px;
          width: 100%;
          margin: 0;

          padding: 10px;
          border: 5px solid $secondary-color;

          @include big-tab() {
            height: 350px;
            margin-right: 12px;
          }

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          &.hasVid {
            position: relative;

            .donate-play {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              pointer-events: none;
              @include center();

              svg {
                font-size: 50px;
                color: $secondary-color;
              }

              span {
                background-color: $white-color;
                border: 1px solid $secondary-color;
              }
            }

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              background-color: #00000036;
            }
          }
        }
      }

      .up-left {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .bottom-wrap {
    h3 {
      font-weight: 700;
      color: #3a4149;
      margin-bottom: 10px;
    }

    .radioBtn {
      display: flex;
      flex-direction: column;

      @include small-tab() {
        flex-direction: row;
      }

      input {
        padding-right: 5px;
      }

      label {
        font-weight: 500;
        color: #3a4149;
        margin-right: 25px;
      }
    }

    p {
      margin-top: 10px;
      color: $text-color;

      span {
        font-weight: 500;
        color: #3a4149;
      }
    }

    .submit-btn {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      button {
        padding: 8px 30px;
        background-color: $secondary-color;
        color: $white-color;
        border: 0;
        font-weight: 600;
        border-radius: 5px;
      }
    }
  }

  .donationAddOns {
    span.sub {
      font-size: 12px;
      color: #7c848da8;
    }

    p {
      color: $text-color;
      font-size: 14px;
      margin-bottom: 0;
    }

    .donationAddOns-board {
      display: flex;
      height: 41px;
      justify-content: space-between;
      border: 1px solid $secondary-color;
      padding: 1px;

      .amount {
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          width: 40px;
          height: 100%;
          object-fit: cover;
          margin-right: 8px;
        }

        span {
          color: $dark-text-color;
        }

        input {
          height: 100%;
          width: 80%;
          padding-left: 5px;
          background-color: transparent;
          border: 0;
          outline: 0;
          position: relative;

          &::after {
            content: "";
            width: 100%;
            position: absolute;
            height: 100%;
            // background-color: red;
          }
        }
      }

      .count-wrap {
        @include center();
        margin-right: 5px;

        .count {
          display: flex;
          align-items: center;

          button {
            width: 25px;
            height: 25px;
            @include center();
            background-color: transparent;
            border: 1px solid #7c828b38;
            color: $secondary-color;

            &.disabled {
              pointer-events: none;
            }
          }

          .showCount {
            width: 30px;
            height: 25px;
            display: block;
            text-align: center;
            border-top: 1px solid #7c828b38;
            border-bottom: 1px solid #7c828b38;
          }
        }
      }
    }
  }
}

.auth-modal {
  position: relative;

  .close-svg {
    position: absolute;
    z-index: 10;
    right: 10px;
    top: 10px;

    svg {
      font-size: 24px;
      color: $text-color;
      transition: 0.4s;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .popup-left-side {
    height: 500px;
    position: relative;
    padding: 25px;
    display: none;

    @include small-tab() {
      display: block;
    }

    figure.bg_img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: 100%;
      width: 100%;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    figure.popup-img {
      position: absolute;
      right: 20px;
      bottom: 0;
      z-index: 0;
      height: 100px;
      width: 100px;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }

    .left-content {
      position: relative;
      height: 100%;

      h4 {
        color: #b9b8bc;
        text-transform: uppercase;
        font-size: 24px;
      }

      .main-content {
        height: 90%;
        @include center();

        h3 {
          color: #5b6877;
          font-size: 34px;
          font-family: "Barlow Condensed", sans-serif;
          letter-spacing: 3px;
          font-weight: 300;
          word-spacing: 5px;

          span {
            color: $primary-color;
          }
        }
      }
    }
  }

  .pop-up-form {
    padding: 30px 20px;

    @include small-tab() {
      padding: 0 30px;
    }

    .heading {
      h2 {
        font-weight: 500;
        font-size: 20px;
        text-transform: uppercase;
        font-family: "Barlow", sans-serif;
        padding-bottom: 15px;
        border-bottom: 1px solid #7c848d2b;
        position: relative;

        @include small-tab() {
          font-size: 26px;
        }

        &::before {
          content: "";
          position: absolute;
          width: 65%;
          height: 3px;
          background-color: $primary-color;
          bottom: -2px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }

    p {
      text-align: left;
      font-size: 18px;
      padding: 10px 0;
      color: $text-color;

      @include small-tab() {
        padding: 10px 0 20px;
        font-size: 20px;
      }
    }

    form {
      text-align: center;

      input {
        width: 100%;
        background-color: #f4f4f4;
        height: 45px;
        border: 0;
        outline: 0;
        padding: 0 20px;
        margin-bottom: 20px;
        font-size: 18px;
        color: $text-color;
        text-align: center;

        @include small-tab() {
          height: 50px;
        }

        &::placeholder {
          color: #b7b7b7;
        }
      }

      .pop-form-btn {
        button {
          height: 45px;
          border: 0;
          width: 100%;
          outline: 0;
          padding: 0 10px;
          text-transform: uppercase;
          font-size: 20px;
          color: $white-color;

          @include small-tab() {
            height: 50px;
            font-size: 20px;
          }
        }

        &.disabled {
          button {
            pointer-events: none;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.modal {
  .modal-dialog {
    max-width: 900px;

    .modal-content {
      border-radius: 0;
      border: 0;

      @include mobile() {
        width: 98%;
        margin: auto;
      }

      .modal-wrap {
        position: relative;

        .close-svg {
          &.btn {
            &:focus {
              box-shadow: none;
            }
          }

          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 20px;
          color: $text-color;
          cursor: pointer;
          z-index: 10;
        }

        figure {
          margin: 0;
          width: 100%;
          height: 300px;

          @include small-tab() {
            height: 100%;
          }

          img {
            object-fit: cover;
            object-position: top;
            height: 100%;
            width: 100%;
          }
        }

        .pop-up-form {
          .pop-up-top {
            display: flex;
            position: sticky;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $text-color;
            padding-bottom: 10px;
            margin-bottom: 10px;

            .heading {
              text-align: left;
              margin-bottom: 10px;

              .head-1 {
                font-size: 30px;
                margin-bottom: 0;
              }

              .head-2 {
                font-size: 30px;
                margin-bottom: 0;
              }
            }

            .hare-krishna-logo-fig {
              width: 80px;
              height: 80px;
              pointer-events: none;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .smallerText {
            font-size: 12px;
            color: $text-color;
            margin: 0;
          }

          .label-input-wrap {
            margin-bottom: 8px;

            @include mobile() {
              margin-bottom: 13px;
            }

            label {
              color: #3a4149;
              font-weight: 600;
              margin-bottom: 3px;

              @include mobile() {
                margin-bottom: 5px;
              }
            }

            select,
            textarea,
            input {
              width: 100%;
              padding: 3px 10px;
              border-radius: 0 !important;
              color: $text-color;
              border: 1px solid #3a414931;

              &:focus-visible {
                outline: 0;
                border: 1px solid $secondary-color;
              }
            }
          }

          .pop-form-btn {
            padding-top: 20px;
            width: 100%;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              @include mobile() {
                padding: 5px;
                font-size: 20px;
              }

              width: 100%;
              font-weight: 500;
              background-color: $secondary-color;
              border: 0;
              color: $white-color;
              padding: 8px;

              svg {
                position: absolute;
                right: 20px;
              }
            }
          }

          padding: 20px;

          @include small-tab() {
            padding: 50px 36px;
            padding-left: 12px;
            padding-top: 15px;
          }

          @include big-tab() {
            padding: 50px 48px;
            padding-left: 24px;
            padding-bottom: 25px;
          }

          span {
            padding-right: 75px;
            border-top: 4px solid $secondary-color;
            margin: 0;
            line-height: 0;
            font-size: 0;
            // @include mobile(){
            //     font-size: 20px;
            // }
          }

          p {
            margin-top: 5px;
            color: $text-color;
          }
        }

        .modal-tab-wrap {
          margin-top: 20px;
          height: 100%;

          @include small-tab() {
            margin-top: 0;
          }

          .nav-tabs {
            border: 0;
            margin-bottom: 30px;
            border: 1px solid #f3b01b38;

            li {
              flex-basis: 50%;
              margin-bottom: 0;

              &:first-child {
                border-right: 1px solid #f3b01b38;
              }

              button {
                width: 100%;
                border: 0;
                outline: 0;
                color: $secondary-color;
                margin-bottom: 0;
                border-bottom: 2px solid transparent;
                font-size: 14px;
                padding: 5px;
                background-color: transparent;
                border-radius: 0;
                height: 100%;

                @include small-tab() {
                  padding: 10px 5px;
                  font-size: 15px;
                }

                &.active {
                  border-bottom: 2px solid $secondary-color;
                  font-weight: 600;
                }
              }
            }
          }

          .tab-content {
            .popup-text-wrap {
              //   height: 350px;
              overflow-y: auto;
              padding-top: 30px;

              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-thumb {
                background: $secondary-color;
                border-radius: 10px;
              }

              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 3px #f3b01b38;
                border-radius: 10px;
              }

              ul {
                list-style-type: none;
                padding-left: 0;
                margin-top: 20px;

                li {
                  padding-left: 25px;
                  position: relative;

                  &::before {
                    content: "";
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    left: 0;
                    top: 6px;
                    background-image: url("../../public/images/feather.svg");
                    background-size: cover;
                    background-position: center;
                  }
                }
              }
            }
          }

          &.courses-mod {
            .nav-tabs {
              margin-bottom: 0;
            }
          }
        }
      }

      .time-wrap {
        margin-bottom: 15px;

        p {
          font-size: 14px;
          padding: 0;
          margin-bottom: 10px;
          text-align: center;
        }

        .timer_span {
          color: $primary-color;
          border: 1px solid $primary-color;
          padding: 0 8px;
          margin-top: 10px;
        }

        button {
          color: $primary-color;
          font-weight: 600;
          background-color: transparent;
          border: 0;
          outline: 0;
          margin-top: 5px;
        }
      }
    }
  }
}

.yt-videos {
  position: relative;
  z-index: 50;
  background-color: #fff;

  .video-wrap {
    .video-holder {
      border: 6px solid $secondary-color;
      margin-bottom: 80px;

      @include small-mobile() {
        margin-bottom: 90px;
      }

      @include small-tab() {
        margin-bottom: 70px;
      }

      @include desktop() {
        margin-bottom: 80px;
      }

      @include mid-desktop() {
        margin-bottom: 90px;
      }

      transition: 0.4s;

      a {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        width: 100%;
        height: 100%;

        figure {
          position: relative;
          width: 100%;
          height: 220px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .thumbnil {
          &::before {
            position: absolute;
            content: "";
            transition: 0.4s;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #3a414944;
          }
        }

        .reason {
          position: absolute;
          width: 80%;
          text-align: center;
          background-color: #fff;
          display: flex;

          padding: 10px 20px;
          align-items: center;
          justify-self: center;
          top: 85%;
          transition: 0.4s;
          display: flex;
          justify-self: center;
          padding: 10px 10px;

          @include small-mobile() {
            padding: 5px 5px;
            margin: 10px 40px;
          }

          @include mobile() {
            margin: 10px 40px;
          }

          @include small-tab() {
            margin: 0 20px;
            padding: 10px 20px;
            top: 85%;
          }

          @include desktop() {
            margin: 0 20px;
            padding: 5px 15px;
          }

          @include mid-desktop() {
            top: 87%;
            padding: 10px 20px;
          }

          p {
            width: 100%;
            text-align: center;
            color: $secondary-color;
            font-size: 18px;
            margin-bottom: 0;

            @include small-mobile() {
              font-size: 20px;
            }

            @include small-tab() {
              font-size: 18px;
            }

            @include big-tab() {
              font-size: 20px;
            }

            @include desktop() {
              font-size: 20px;
            }
          }
        }

        .yt-logo {
          z-index: 50;
          position: absolute;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;

          color: red;

          figure {
            width: 80px;
            height: 80px;

            svg {
              font-size: 40px;
            }
          }
        }
      }

      &:hover {
        .reason {
          box-shadow: 5px 10px 18px #b1b0b069;
        }

        figure::before {
          background-color: #3a414976;
        }
      }
    }
  }
}

section.services-sec {
  background-color: #fff;
  position: relative;

  .line {
    width: 150px;
    margin: 20px;
    border-top: 5px solid $secondary-color;
  }

  .books-wrap {
    .book-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 1px solid #7c848d1a;

      // margin-bottom: 20px;
      figure {
        width: 100%;
        height: 500px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 800;
        color: $secondary-color;
        font-size: 22px;
        margin: 0 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
      }

      p {
        color: #3a4149;
        font-weight: 600;
        margin: 0 10px;
      }

      button {
        margin: 10px;
        margin-top: 20px;
        border: 0;
        padding: 7px 10px;
        color: $white-color;
        font-weight: 500;
      }

      .custom-btn-cls {
        margin: 10px;
        margin-top: 20px;

        svg {
          font-size: 12px;
          margin-top: -10px;
        }
      }
    }
  }
}

section.unable-to-make-it {
  z-index: 51;
}

section.yatras {
  position: relative;
  background-color: #fff;
}

section.yatras-wrap {
  .yatras-wrap {
    .yatra-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 1px solid #7c848d1a;

      // margin-bottom: 20px;
      figure {
        width: 100%;
        height: 500px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 800;
        color: $secondary-color;
        font-size: 22px;
        margin: 0 10px;
        padding-bottom: 10px;
      }

      p {
        color: #3a4149;
        font-weight: 600;
        margin: 0 10px;
      }

      button {
        margin: 10px;
        margin-top: 20px;
        border: 0;
        padding: 7px 10px;
        color: $white-color;
        font-weight: 500;
      }
    }
  }
}

section.volunteer {
  position: relative;
  background-color: #fff;
}

section.volunteer-info-sec {
  background-color: #f3f3f3;

  .heading {
    display: flex;
    margin-bottom: 30px;
    justify-content: flex-start;
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      color: $text-color;
      font-size: 15px;

      @include mobile() {
        font-size: 18px;
      }

      @include small-tab() {
        font-size: 20px;
      }

      line-height: 2rem;

      a {
        color: #f3b01a;
        text-decoration: none;
      }
    }
  }

  .volunteer-form {
    form {
      .input-wrap {
        margin-bottom: 25px;
        position: relative;

        @include small-tab() {
          margin-bottom: 10px;
        }

        span {
          position: absolute;
          left: 0;
          bottom: -20px;
          color: $primary-color;
          font-size: 14px;
        }

        @include mobile() {
          margin-bottom: 25px;
        }

        label {
          display: block;
          font-weight: 600;
          color: $dark-text-color;
          font-size: 18px;
          margin-bottom: 8px;

          &.imp {
            &::after {
              content: "*";
              color: $secondary-color;
              margin-left: 5px;
              position: relative;
              top: -5px;
            }
          }
        }

        input,
        textarea {
          width: 100%;
          border: 0;
          outline: 0;
          background-color: #d9e1ec6b;
          padding: 10px;
        }

        textarea {
          height: 100px;
          resize: none;
        }
      }

      button {
        font-size: 18px;
        font-weight: 600;

        @include mobile() {
          height: 50px;
        }
      }
    }
  }
}

section.FoodForLife {
  position: relative;
  background-color: #fff;

  &.FoodForLife2 {
    background-color: #f3f3f3;
  }

  .custom-btn-cls {
    .custom-btn {
      margin-top: 15px;
    }

    p {
      color: $text-color;
    }

    .heading {
      p {
        text-align: left;
      }
    }
  }
}

section.vrindavan-card {
  position: relative;
  background-color: #f3f3f3;

  .vrindavan-wrap {
    border: 6px solid $secondary-color;
    padding: 10px;

    @include small-tab() {
      padding: 20px;
      padding-right: 50px;
    }

    .yatra-fig {
      overflow: hidden;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .custom-btn-cls {
      width: 150px;
      padding: 10px;
      margin-left: 0;

      @include small-tab() {
        width: 200px;
      }
    }

    .heading {
      height: 100%;

      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include small-tab() {
        margin-left: 20px;
      }

      @include big-tab() {
        margin-left: 40px;
      }

      .head-1 {
        @include big-tab() {
          font-size: 50px;
        }
      }

      .head-2 {
        @include big-tab() {
          font-size: 50px;
        }
      }

      p {
        @include desktop() {
          font-size: 20px;
        }
      }
    }

    .date-div {
      position: relative;
      display: flex;
      width: fit-content;

      h5 {
        margin-bottom: 0;
        color: $primary-color;
        padding: 10px 12px;
        font-size: 15px;
        font-weight: 600;

        @include mobile() {
          font-size: 18px;
        }

        @include small-tab() {
          font-size: 16px;
        }

        @include desktop() {
          font-size: 18px;
        }
      }

      .date-up {
        position: absolute;
        top: 0;
        left: 0;
      }

      .date-down {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      figure {
        padding: 0;
        width: 20px;
        margin: 0;

        @include desktop() {
          width: 25px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

section.contact-wrap {
  background-color: #f3f3f3;
  position: relative;

  .contact-address {
    text-align: center;

    .contact-logo {
      figure {
        width: 120px;
        margin: auto;

        @include small-tab() {
          width: 200px;
        }

        img {
          width: 100%;
        }
      }

      h5 {
        margin-top: 10px;
        text-transform: capitalize;
        color: $primary-color;
        font-weight: 700;
      }
    }

    p {
      color: $text-color;
      font-size: 16px;
      margin-bottom: 2px;

      @include small-tab() {
        font-size: 18px;
      }

      span {
        &.key {
          font-weight: 700;
          color: $text-color;
        }

        &.highlight {
          color: $secondary-color;
        }
      }

      .value {
        text-decoration: none;
        color: $text-color;
      }
    }

    a {
      text-decoration: none;
      color: $text-color;
      transition: 0.4s;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  .contact-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-title {
      h4 {
        text-align: center;
        text-transform: uppercase;
        font-size: 40px;
        font-weight: 700;
        color: $secondary-color;

        span {
          color: $text-color;
        }
      }
    }

    form {
      .input-wrap {
        margin-bottom: 25px;
        position: relative;

        @include small-tab() {
          margin-bottom: 10px;
        }

        span {
          position: absolute;
          left: 0;
          bottom: -20px;
          color: $primary-color;
          font-size: 14px;
        }

        @include mobile() {
          margin-bottom: 25px;
        }

        label {
          display: block;
          font-weight: 600;
          color: $dark-text-color;
          font-size: 18px;
          margin-bottom: 8px;

          &.imp {
            &::after {
              content: "*";
              color: $secondary-color;
              margin-left: 5px;
              position: relative;
              top: -5px;
            }
          }
        }

        input,
        textarea {
          width: 100%;
          border: 0;
          outline: 0;
          background-color: #d9e1ec6b;
          padding: 10px;
          height: 40px;

          @include mobile() {
            height: 50px;
          }
        }

        textarea {
          height: 160px;
          resize: none;
        }
      }

      button {
        font-size: 18px;
        font-weight: 600;

        @include mobile() {
          height: 50px;
        }
      }
    }
  }
}

section.mission {
  background-color: #f3f3f3;

  .mission-wrap {
    figure {
      @include center();

      img {
        border: 5px solid $secondary-color;
        padding: 10px;
        width: 100%;

        @include small-tab() {
          width: 50%;
        }

        @include big-tab() {
          width: 100%;
        }
      }
    }

    .heading {
      text-align: left;

      .head-1 {
        text-align: center;
      }

      p {
        font-size: 16px;

        @include mobile() {
          font-size: 20px;
        }
      }
    }
  }

  &.lifememberPage {
    a {
      &.custom-btn-cls {
        padding: 10px 16px;
      }
    }
  }

  .custom-btn-cls {
    padding: 10px;
  }
}

section.founder-info-sec {
  border-top: 6px solid $secondary-color;
  background-color: #f3f3f3;
  position: relative;

  .founder-img {
    display: none;
    width: 40%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include big-tab() {
      display: block;
    }
  }

  .heading {
    text-align: left;
    text-align: center;

    @include big-tab() {
      margin: 60px 0;
      text-align: unset;
    }

    @include desktop() {
      margin: 150px 0;
    }

    .head-1 {
      font-family: "Barlow", sans-serif;
    }

    .head-2 {
      font-family: "Barlow", sans-serif;
    }

    p {
      text-align: left;
      font-size: 16px;

      @include mobile() {
        font-size: 20px;
      }
    }

    button {
      margin-top: 20px;
      font-weight: 500;
      padding: 10px;
    }

    .h2-wrap {
      margin-bottom: 20px;
    }
  }

  figure {
    width: 80%;
    margin: auto;

    @include mobile() {
      width: 300px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}

section.about-video {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 500px;

  @include small-tab() {
    height: 600px;
  }

  @include big-tab() {
    height: 700px;
  }

  figure.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0;
    width: 60px;
    height: 60px;

    @include small-tab() {
      width: 80px;
      height: 80px;
    }

    @include big-tab() {
      width: 100px;
      height: 100px;
    }

    img {
      width: 100%;
    }
  }

  .title {
    display: inline-block;
    border-bottom: 5px solid $secondary-color;

    h2 {
      text-decoration: none;
      color: $white-color;
      font-family: "Barlow Condensed", sans-serif;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 20px;

      @include mobile() {
        font-size: 30px;
      }

      @include small-tab() {
        font-size: 40px;
      }

      @include big-tab() {
        font-size: 50px;
      }

      span {
        font-size: 40px;

        @include mobile() {
          font-size: 50px;
        }

        @include small-tab() {
          font-size: 60px;
        }

        @include big-tab() {
          font-size: 70px;
        }
      }
    }
  }
}

section.madhav-temple {
  p {
    color: $text-color;
    font-size: 16px;

    @include big-tab() {
      font-size: 20px;
    }
  }

  figure {
    border: 5px solid $secondary-color;
    padding: 10px;

    &.right-img {
      @include small-tab() {
        height: 500px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }
}

// section.food-life-slider {
//   .slider-wrapper {
//     @include big-tab() {
//       margin: 0 100px;
//     }
//     .food-slider {
//       figure {
//         width: 100%;

//         img {
//           width: 100%;
//           object-fit: cover;
//           object-position: center;
//         }
//       }
//     }
//   }
//   .arrow-wrap {
//     @include big-tab() {
//       padding: 0 50px;
//     }
//   }
// }

section.food-life-slider {
  .food-slider {
    figure {
      width: 100%;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &.campaign-stats-wrap {
    .slider-wrapper {
      @include big-tab() {
        margin: 0 100px;
      }
    }

    .arrow-wrap {
      top: 40%;
      transform: translateY(-40%);

      @include big-tab() {
        padding: 0 50px;
      }

      @include large-desktop() {
        padding: 0 300px;
      }
    }
  }
}

section.volunteer-slider-sec {
  .volunteer-slider {
    figure {
      width: 100%;

      img {
        border: 2px solid #f3b01b;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .arrow-wrap {
    top: 65%;
  }
}

section.acharyas-sec {
  background-color: #f3f3f3;
  padding-bottom: 40px;

  .acharyas-card-wrap {
    .acharya-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 1px solid #7c848d1a;

      // margin-bottom: 20px;
      figure {
        button {
          position: absolute;
          bottom: 10px;
          margin: auto;
          display: block;
          opacity: 1;

          @include mobile() {
            opacity: 0;
          }

          transition: 0.4s;
        }

        width: 100%;
        height: 500px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: rgb(0, 0, 0);
          background: linear-gradient(180deg, #00000000 0%, #00000040 100%);
          transform: translateY(100%);
          transition: 0.4s;
        }

        &:hover {
          &::before {
            transform: translateY(0);
          }

          button {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 800;
        color: $secondary-color;
        font-size: 22px;
        margin: 0 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        text-align: center;
      }

      p {
        color: #3a4149;
        // font-weight: 600;
        margin: 0 10px;
        margin-bottom: 10px;
      }
    }
  }
}

.modal {
  .modal-dialog {
    max-width: 900px;

    .modal-content {
      border-radius: 0;
      border: 0;

      @include mobile() {
        width: 95%;

        margin: auto;
      }

      .modal-wrap-read {
        max-height: 500px;

        @include mobile() {
          max-height: 600px;
        }

        max-height: 600px;
        position: relative;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: $secondary-color;
          border-radius: 10px;
        }

        figure {
          margin: 0;
          width: 100%;
          height: 300px;

          @include small-tab() {
            height: 100%;
          }

          img {
            object-fit: cover;
            object-position: top;
            height: 100%;
            width: 100%;
          }
        }

        .pop-up-form {
          .pop-up-top {
            display: flex;
            background-color: #fff;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $text-color;
            padding-bottom: 10px;
            margin-bottom: 10px;
            padding-top: 20px;
            position: sticky;
            top: 0;

            .close-btn {
              &.btn {
                &:focus {
                  box-shadow: none;
                }
              }

              position: absolute;
              top: -7px;
              right: -15px;
              font-size: 15px;

              @include mobile() {
                top: -6px;
                right: -30px;
                font-size: 18px;
              }

              color: $text-color;
              cursor: pointer;
            }

            .heading {
              text-align: left;
              margin-bottom: 10px;

              .head-1 {
                font-size: 30px;
                margin-bottom: 0;
              }

              .head-2 {
                font-size: 20px;
                margin-bottom: 0;

                @include mobile() {
                  font-size: 30px;
                }
              }
            }

            .hare-krishna-logo-fig {
              width: 70px;
              height: 70px;

              @include mobile() {
                width: 80px;
                height: 80px;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          padding: 15px;
          padding-top: 0;

          @include small-tab() {
            padding: 30px;
            padding-top: 0;
          }

          span {
            padding-right: 75px;
            border-top: 4px solid $secondary-color;
            margin: 0;
            line-height: 0;
            font-size: 0;
          }

          p {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 15px;

            @include mobile() {
              font-size: 18px;
            }

            color: $text-color;
          }
        }

        &.content-modal {
          padding-bottom: 100px;

          .pop-up-form {
            padding: 15px;

            @include small-tab() {
              padding: 30px;
            }
          }
        }
      }
    }
  }
}

section.goswamis-sec {
  background-color: #f3f3f3;
  padding-bottom: 50px;

  .goswamis-card-wrap {
    .goswami-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 1px solid #7c848d1a;

      // margin-bottom: 20px;
      figure {
        button {
          position: absolute;
          bottom: 10px;
          margin: auto;
          display: block;
          opacity: 1;

          @include mobile() {
            opacity: 0;
          }

          transition: 0.4s;
        }

        width: 100%;
        height: 500px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: rgb(0, 0, 0);
          background: linear-gradient(180deg, #00000000 0%, #00000040 100%);
          transform: translateY(100%);
          transition: 0.4s;
        }

        &:hover {
          &::before {
            transform: translateY(0);
          }

          button {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 800;
        color: $secondary-color;
        font-size: 22px;
        margin: 0 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        text-align: center;
      }

      p {
        color: #3a4149;
        // font-weight: 600;
        margin: 0 10px;
        margin-bottom: 10px;
      }
    }
  }

  a {
    display: inline-block;
    width: 200px;
    margin: 10px;
    text-align: center;
    margin-top: 20px;
    border: 0;
    padding: 10px 15px;
    color: $white-color;
    font-weight: 500;
  }
}

section.foodforlife-video {
  padding: 0;
}

.vaishnavism-banner {
  border: 5px solid #f3b01b;
  padding: 10px;
}

section.thank-you {
  padding: 300px 0 150px;
  background-color: #f4f4f4;

  .thank-you-wrapper {
    background-color: $white-color;
    padding: 50px 0;
    box-shadow: 2px 5px 26px 6px #4e14161a;

    .head-1 {
      font-size: 40px;
      font-weight: 400;
    }

    .icon {
      border: 2px solid $secondary-color;
      width: 80px;
      height: 80px;
      @include center();
      margin: 0 auto 15px auto;
      border-radius: 50%;

      svg {
        font-size: 60px;
        color: $secondary-color;
      }

      &.failed {
        border: 2px solid #dc3545;

        svg {
          color: #dc3545;
        }
      }
    }

    p {
      font-size: 20px;
      text-align: center;
      margin-top: 20px;
      color: #7c848d;
    }

    button {
      border: none;
      text-align: center;
      margin-top: 20px;
      font-weight: 700;
      padding: 10px 20px;
      text-decoration: none;
      color: $secondary-color;
      background-color: #f3af1b4c;
      border-radius: 5px;
      transition: 0.4s;

      &:hover {
        background-color: $secondary-color;
        color: $white-color;
      }
    }

    .backToHome {
      margin-top: 20px;
      color: $primary-color;
      font-size: 18px;
      text-decoration: none;

      svg {
        width: 40px;
        height: 26px;
      }
    }
  }
}

.spinner-border {
  width: 15px;
  height: 15px;
}

section.pageNotFound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 80px;
  min-height: 92vh;
  @include big-tab() {
    padding-top: 150px;
  }

  background-color: #fef7e8;

  .pageNotFound-wrapper {
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.005);
    -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.03);
    -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
    background-color: #fff;
    padding: 20px 10px;

    @include small-mobile() {
      padding: 40px;
    }

    @include big-tab() {
      padding: 50px 0;
      padding-right: 40px;
    }

    margin: 0 10px;

    .round-404 {
      background-color: #fef7e8;
      height: 200px;
      width: 200px;

      @include mobile() {
        width: 300px;
        height: 300px;
      }

      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      figure {
        border-radius: 50%;
        width: 100%;
        height: 100%;
        padding: 20px;
        @include center();

        img {
          width: 80%;
        }
      }
    }

    .content-404 {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    h1 {
      font-weight: 600;
      font-size: 80px;
      color: $primary-color;
      text-align: center;
      margin-bottom: 0;

      @include mobile() {
        font-size: 110px;
      }
    }

    h2 {
      position: relative;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      margin-top: -8px;
      color: $secondary-color;

      @include mobile() {
        font-size: 24px;
      }
    }

    .textline1-404 {
      margin-top: 30px;
      position: relative;
      font-weight: 600;
      font-size: 15px;
      text-align: center;
      color: $text-color;
      margin-bottom: 20px;

      @include mobile() {
        margin-bottom: 24px;
        font-size: 20px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 80px;
        height: 3px;
        background-color: #f3b01b;
        bottom: 25px;

        @include mobile() {
          bottom: 35px;
        }

        @include big-tab() {
          bottom: 45px;
          margin-top: 0;
        }

        left: 50%;
        transform: translateX(-50%);
      }
    }

    .textline2-404 {
      font-weight: 600;
      font-size: 23px;
      text-align: center;
      margin-top: -6px;
      margin-bottom: 0;
      font-family: "Barlow Condensed", sans-serif;
      color: #f3b01b;
      font-weight: 700;
      color: $secondary-color;

      @include mobile() {
        font-size: 30px;
      }

      @include big-tab() {
        font-size: 28px;
      }

      @include desktop() {
        font-size: 35px;
      }

      @include mid-desktop() {
        font-size: 40px;
      }
    }

    .textline3-404 {
      font-weight: 600;
      font-size: 22px;
      text-align: center;
      color: $primary-color;
      font-family: "Barlow Condensed", sans-serif;
      color: #9f1d21;
      font-weight: 700;

      @include mobile() {
        font-size: 30px;
      }

      @include big-tab() {
        font-size: 28px;
      }

      @include desktop() {
        font-size: 35px;
      }

      @include mid-desktop() {
        font-size: 40px;
      }

      &.small {
        font-size: 16px;

        @include mobile() {
          font-size: 18px;
        }

        @include small-tab() {
          font-size: 20px;
        }
      }
    }

    .custom-btn-cls {
      font-weight: 600;
      font-size: 14px;
      padding: 6px;

      @include big-tab() {
        font-size: 14px;
      }

      @include desktop() {
        font-size: 15px;
        padding: 10px;
      }

      @include mid-desktop() {
        font-size: 16px;
        padding: 8px 16px;
      }

      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
        font-size: 22px;
      }
    }
  }
}

section.courses-sec {
  background-color: #f3f3f3;
  padding-bottom: 50px;

  .course-card-wrap {
    .course-card {
      .book-top {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      border: 1px solid #7c848d1a;

      // margin-bottom: 20px;
      figure {
        button {
          position: absolute;
          bottom: 10px;
          margin: auto;
          display: block;
          opacity: 1;

          @include mobile() {
            opacity: 0;
          }

          transition: 0.4s;
        }

        width: 100%;
        height: 500px;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          content: "";
          background: rgb(0, 0, 0);
          background: linear-gradient(180deg, #00000000 0%, #00000040 100%);
          transform: translateY(100%);
          transition: 0.4s;
        }

        &:hover {
          &::before {
            transform: translateY(0);
          }

          button {
            opacity: 1;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-weight: 800;
        color: $secondary-color;
        font-size: 22px;
        margin: 0 10px;
        padding-bottom: 10px;
        text-transform: capitalize;
        text-align: center;
      }

      p {
        color: #3a4149;
        // font-weight: 600;

        margin: 0 10px;
      }

      .custom-btn-cls {
        display: block;
        padding: 5px;
        font-size: 16px;
        width: 160px;
        margin: 15px auto;
      }
    }
  }

  a {
    display: inline-block;
    width: 200px;
    margin: 10px;
    text-align: center;
    margin-top: 20px;
    border: 0;
    padding: 10px 15px;
    color: $white-color;
    font-weight: 500;
  }
}

section.secret-head1-sec {
  background-color: #f3f3f3;

  .list-unstyled {
    padding-top: 20px;

    .bullet {
      font-weight: 500;
      color: $text-color;
      display: flex;
      margin-bottom: 18px;
    }
  }
}

section.secret-head2-sec {
  .list-unstyled {
    padding-top: 20px;

    .bullet {
      font-weight: 500;
      margin-bottom: 18px;
      color: $text-color;
      display: flex;
    }
  }
}

section.secret-head3-sec {
  background-color: #f3f3f3;

  .list-unstyled {
    padding-top: 20px;

    .bullet {
      font-weight: 500;
      margin-bottom: 18px;
      color: $text-color;
      display: flex;
    }
  }
}

section.success-registration-sec {
  .success-registration-wrap {
    .custom-btn-cls {
      width: 200px;
    }

    .note-para {
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      margin-bottom: 0;
      margin: 30px 0;
      color: #3a4149;
    }

    .success-contact {
      p {
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        color: #3a4149;

        a {
          color: #3a4149;

          &:hover {
            color: $secondary-color;
          }
        }
      }
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

section.bhagavadgita-card {
  position: relative;
  background-color: #f3f3f3;

  .bhagavadgita-wrap {
    border: 6px solid $secondary-color;
    padding: 10px;

    @include small-tab() {
      padding: 20px;
      padding-right: 50px;
    }

    .bhagavadgita-fig {
      overflow: hidden;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .heading {
      height: 100%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include small-tab() {
        margin-left: 20px;
      }

      @include big-tab() {
        margin-left: 40px;
      }

      .head-1 {
        @include big-tab() {
          font-size: 50px;
        }
      }

      .head-2 {
        @include big-tab() {
          font-size: 50px;
        }
      }

      p {
        font-size: 16px;

        @include desktop() {
          font-size: 20px;
        }
      }
    }

    .custom-btn-cls {
      width: 150px;
      margin-left: 0;
      padding: 10px;

      @include small-tab() {
        width: 200px;
      }
    }

    .date-div {
      position: relative;
      display: flex;
      width: fit-content;

      h5 {
        margin-bottom: 0;
        color: $primary-color;
        padding: 10px 12px;
        font-size: 15px;
        font-weight: 600;

        @include mobile() {
          font-size: 18px;
        }

        @include small-tab() {
          font-size: 16px;
        }

        @include desktop() {
          font-size: 18px;
        }
      }

      .date-up {
        position: absolute;
        top: 0;
        left: 0;
      }

      .date-down {
        position: absolute;
        bottom: 0;
        right: 0;
      }

      figure {
        padding: 0;
        width: 20px;
        margin: 0;

        @include desktop() {
          width: 25px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

section.wallpaper-wrap {
  padding: 0;
  padding-bottom: 80px;
}

.wallpaper-card {
  margin: 5px;
  position: relative;

  &:hover {
    .hover-plus {
      opacity: 1;
    }
  }

  figure {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: #000;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #00000096 100%);
      transition: 0.4s;
      transform: translateY(100%);
    }

    img {
      height: 100%;
      width: 100%;
    }

    .custom-btn-cls {
      opacity: 0;
      position: absolute;
      bottom: 10px;
    }

    &:hover {
      .custom-btn-cls {
        opacity: 1;
      }

      &::before {
        transform: translateY(0);
      }
    }
  }

  .hover-plus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 35px;
    opacity: 0;
    pointer-events: none;

    @include desktop() {
      font-size: 40px;
    }

    svg {
      color: $secondary-color;
    }
  }
}

.dropdown-wrap {
  text-align: center;

  button {
    border-radius: 0;
    position: relative;
    outline: 0;
    background-color: transparent;
    border: 0;
    z-index: 10;

    &::before {
      content: "";
      transition: 0.4s;
      background-color: $secondary-color;
      transform: scale(1);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &:focus,
    &:hover,
    &.dropdown-toggle {
      border: 0 !important;
      box-shadow: unset !important;
      background-color: $secondary-color !important;
    }

    &:hover {
      &::before {
        transform: scale(1.05);
      }
    }
  }

  .dropdown-menu {
    border-radius: 0;
    left: 0;
    bottom: 0;

    &.show {
      transform: translate(346px, 40px);
    }

    a {
      color: $text-color;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

section.membership-program {
  .heading {
    .head-1 {
      font-size: 32px;
    }

    .head-1 {
      font-size: 42px;
    }
  }
}

section.campaign-heading-sec {
  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0;
      color: $primary-color;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      span {
        width: 80px;
        height: 2px;
        display: inline-block;
        background-color: $text-color;
      }

      @include mobile() {
        font-size: 20px;
      }
    }

    h2 {
      margin-bottom: 0;
      color: $text-color;
      font-size: 18px;
      font-weight: 400;

      @include mobile() {
        font-size: 24px;
      }

      @include big-tab() {
        font-size: 34px;
      }

      span {
        font-weight: 600;
        color: $secondary-color;
      }
    }

    .custom-btn-cls {
      color: $primary-color;
      font-weight: 700;
      width: 200px;
      padding: 10px;

      @include mobile() {
        width: 300px;
      }

      @include big-tab() {
        width: 400px;
        padding: 20px 10px;
      }
    }
  }
}

section.campaign-main-sec {
  padding-top: 0;
  border-top: 1px solid #d4d4d4;

  .donate-bars-wrap {
    height: unset;

    @include big-tab() {
      height: 100%;
    }

    .donate-bar {
      border-bottom: 1px solid #d4d4d4;
      border-left: 1px solid #d4d4d4;
      border-right: 1px solid #d4d4d4;
      display: grid;
      grid-template-columns: minmax(100px, 2fr) minmax(100px, 1fr) minmax(
          100px,
          1fr
        );

      &:nth-of-type(1) {
        border-top: 1px solid #d4d4d4;
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;

        &.mid {
          border-left: 1px solid #d4d4d4;
          border-right: 1px solid #d4d4d4;

          input {
            border: 1px solid #d4d4d4;
            height: 35px;
            text-align: center;
            outline: 0;
            width: 90%;
          }
        }

        &.last {
          justify-content: flex-start;

          @include small-mobile() {
            justify-content: center;
            padding: 10px;
          }
        }
      }

      h4 {
        margin-bottom: 0;
        font-weight: 600;
        color: $dark-text-color;
        font-size: 14px;
        text-align: center;

        @include small-mobile() {
          font-size: 16px;
        }

        @include mobile() {
          font-size: 13px;
        }

        @include desktop() {
          font-size: 18px;
        }
      }

      .custom-btn-cls {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
        padding: 6px 6px;

        svg {
          font-size: 25px;

          @include mobile() {
            padding-left: 5px;
          }
        }

        @include small-mobile() {
          font-size: 12px;
        }

        @include mobile() {
          width: 100%;
          padding: 6px 15px;
          font-size: 14px;
        }
      }
    }
  }

  .story-slider {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .thumbnail-slider-wrap {
      .splide__slide {
        border: 5px solid #b4b7bd;

        &.is-active {
          border: 5px solid $secondary-color;
        }

        //     &:focus-visible {
        //         outline: none;
        //     }

        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
      }

      .thumbnail-slide {
        margin-top: 20px;

        figure {
          height: 110px;
          width: 100%;

          @include big-tab() {
            width: 100%;
          }

          padding: 5px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .image-preview {
      .image-preview-slide {
        figure {
          width: 100%;
          height: 300px;

          @include mobile() {
            height: 320px;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }
    }
  }

  .donate-links {
    background-color: #e5a98e;
    padding: 5px;
    text-align: center;

    a {
      font-size: 15px;
      font-weight: 600;
      text-decoration: none;
      color: $white-color;

      @include small-tab() {
        font-size: 18px;
      }

      @include big-tab() {
        font-size: 22px;
      }
    }
  }

  .payment-gateways {
    .gateway-header {
      background-color: $primary-color;
      color: $white-color;
      padding: 6px 15px;
      margin-bottom: 20px;

      h4 {
        font-size: 18px;

        @include small-tab() {
          font-size: 20px;
        }
      }
    }

    .gateway-content {
      &.grid {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
      }

      .key-value {
        font-size: 16px;
        color: $text-color;

        span {
          color: $dark-text-color;
          font-weight: 600;
        }

        button {
          outline: 0;
          color: $dark-text-color;
          border-radius: 0;
          margin-left: 5px;
          background-color: #cdd7e3;
          padding: 0px 6px;
          font-size: 10px;
          border: 1px solid #a6b2bf;
        }
      }

      .short-note {
        font-size: 14px;
        margin-top: 10px;
        display: inline-block;
      }

      .paytm-logo {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;

        span {
          color: #02b9ef;
          text-transform: uppercase;
        }

        img {
          width: 70px;
          margin-right: 10px;
        }
      }

      .upi-logo {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 10px;

        span {
          color: $dark-text-color;
          text-transform: uppercase;
        }

        img {
          width: 70px;
          margin-right: 10px;
        }
      }

      .gateway-btn {
        margin-top: 10px;

        button,
        a {
          text-transform: uppercase;
          background-color: #02b9ef;
          border: 0;
          outline: 0;
          color: $white-color;
          padding: 3px 8px 4px;
          text-decoration: none;
        }
      }

      figure {
        width: 120px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .bank-details-wrap {
    display: flex;
    flex-direction: column;

    .pay-details {
      h6 {
        font-size: 18px;

        @include mobile() {
          font-size: 22px;
        }

        font-weight: 700;
        color: $primary-color;
        margin-bottom: 0;
        padding: 10px 0;
        margin-top: 5px;
      }

      p {
        font-size: 16px;

        @include mobile() {
          font-size: 20px;
        }

        font-weight: 400;
        margin-bottom: 0;
        color: $dark-text-color;
        padding: 5px 0;
      }

      a {
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;

        @include mobile() {
          font-size: 20px;
        }

        color: $dark-text-color;
        padding: 5px 0;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }

  .scanner-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include big-tab() {
      align-items: center;
    }

    h6 {
      font-size: 18px;

      @include mobile() {
        font-size: 22px;
      }

      font-weight: 700;
      color: $primary-color;
      margin-bottom: 0;
      padding: 10px 0;
      margin-top: 5px;
    }

    figure {
      border: 3px solid $secondary-color;
      padding: 10px;
      height: 250px;
      width: 250px;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  .custom-btn-cls {
    &.foreign-donate-btn {
      color: $primary-color;
      font-weight: 700;
      width: 200px;
      padding: 10px;

      @include mobile() {
        width: 300px;
      }

      @include big-tab() {
        width: 400px;
        padding: 20px 10px;
      }
    }
  }

  .benefits-wrap {
    background-color: #fff2cd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    h6 {
      color: $primary-color;
      font-size: 20px;
      font-weight: 500;

      @include mobile() {
        font-size: 25px;
      }

      @include big-tab() {
        font-size: 30px;
      }
    }

    p {
      color: $dark-text-color;
      font-size: 20px;
      font-weight: 500;

      @include mobile() {
        font-size: 25px;
      }

      @include big-tab() {
        font-size: 30px;
      }
    }
  }

  .request-wrap {
    background-color: #eaeaea;
    padding: 10px;

    @include big-tab() {
      padding: 20px;
    }

    p {
      margin-bottom: 0;
      color: $dark-text-color;

      a {
        text-decoration: none;
        font-weight: 500;
        color: $dark-text-color;

        &:hover {
          color: $secondary-color;
        }
      }

      @include mobile() {
        font-size: 20px;
      }

      @include big-tab() {
        font-size: 22px;
      }
    }
  }

  .lockdown-info {
    border: 3px solid $secondary-color;
    text-align: center;

    h6 {
      color: $white-color;
      margin: 5px;
      background-color: $secondary-color;
      padding: 10px;
      font-weight: 600;

      @include desktop() {
        font-size: 22px;
      }
    }
  }
}

section.cry-for-food-sec {
  background-color: #f3f3f3;

  h2 {
    display: flex;
    justify-content: center;
    font-size: 23px;
    color: $secondary-color;
    font-weight: 700;

    @include mobile() {
      font-size: 28px;
    }

    @include small-tab() {
      font-size: 35px;
    }

    @include big-tab() {
      font-size: 40px;
    }
  }

  .cry-for-food-video {
    position: relative;
    height: 100%;
    display: block;

    .thumbnil {
      border: 5px solid $secondary-color;
      width: 100%;
      height: 400px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    .yt-logo {
      position: absolute;
      top: 0;
      left: 0;
      // transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      pointer-events: none;
      @include center();

      .yt-figure {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          height: 50px;
          width: 50px;
        }
      }
    }
  }

  .cry-for-food-aim {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    text-align: center;
    background-color: #fff0c5;

    p {
      color: $dark-text-color;
      font-size: 18px;

      @include small-tab() {
        font-size: 20px;
      }

      @include big-tab() {
        font-size: 22px;
      }
    }
  }
}

section.campaign-review-sec {
  position: relative;
  border-bottom: 4px solid $secondary-color;

  h2 {
    display: flex;
    justify-content: center;
    font-size: 23px;
    color: $secondary-color;
    font-weight: 700;

    span {
      color: $primary-color;
      margin-left: 8px;
    }

    @include mobile() {
      font-size: 28px;
    }

    @include small-tab() {
      font-size: 35px;
    }

    @include big-tab() {
      font-size: 40px;
    }
  }

  .heading-p {
    text-align: center;
    color: $text-color;
    font-size: 20px;

    @include small-tab() {
      font-size: 22px;
    }
  }

  .splide {
    .splide__slide {
      padding: 20px 0;
    }
  }

  .campaign-review {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.4s;
    padding: 15px 0;
    margin: 10px;

    &:hover {
      // box-shadow: 0 0 19px 3px #dbdde0;
      box-shadow: 0 0 5px 1px #d7d6d6;
    }

    figure {
      height: 115px;
      width: 115px;
      border-radius: 50%;

      @include mobile() {
        height: 150px;
        width: 150px;
      }

      @include big-tab() {
        height: 180px;
        width: 180px;
      }

      img {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .profile-name {
      h5 {
        font-size: 22px;
        color: $primary-color;
        font-weight: 700;
        margin-bottom: 0;
      }

      p {
        color: $text-color;
        font-size: 18px;
        margin-bottom: 5px;
      }
    }

    .profile-review {
      h6 {
        color: $secondary-color;
        font-size: 18px;
        margin-bottom: 20px;
      }

      p {
        color: $text-color;
        font-size: 16px;

        // text-align: left;
        @include mobile() {
          font-size: 18px;
        }

        @include big-tab() {
          padding: 0 20px;
        }
      }
    }
  }
}

section.thank-you-container {
  background-color: #f4f4f4;

  .download-container {
    .certificate-card {
      box-shadow: 0px 0px 20px 10px #bdbdbd59;
      padding: 10px;
      background-color: $white-color;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      figure {
        height: 180px;
        position: relative;
        border: 1px solid #f3b01b5c;

        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
          width: 100%;
        }

        &::before {
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #0000000f;
          top: 0;
          left: 0;
        }
      }

      h4 {
        font-size: 20px;
        color: $primary-color;
        font-weight: 700;
      }

      .certificate-share-wrap {
        @include center();

        a,
        button {
          border: 1px solid $secondary-color;
          width: 40px;
          height: 40px;
          @include center();
          margin: 0 5px;
          background-color: $secondary-color;
          transition: 0.4s;

          svg {
            transition: 0.4s;
            color: $white-color;
          }

          &:hover {
            background-color: $white-color;

            svg {
              color: $secondary-color;
            }
          }
        }
      }

      p {
        color: $text-color;
        font-weight: 500;
        font-size: 16px;
        width: 90%;
      }
    }
  }

  .heading {
    .head-1 {
      img {
        width: 60px;
        margin-bottom: 5px;
      }
    }

    p {
      font-size: 18px;

      @include mobile() {
        font-size: 20px;
      }
    }
  }

  .btns-wrap {
    display: flex;
    flex-direction: column;

    @include mobile() {
      flex-direction: row;
    }

    .custom-btn-cls {
      padding: 8px;
      font-size: 16px;
      margin-top: 10px;
      width: 100%;

      @include mobile() {
        margin-top: 0;
        width: unset;
      }
    }

    .custom-btn {
      padding: 8px 10px;
      width: 100%;

      @include mobile() {
        width: unset;
      }
    }
  }

  .thank-you-card-wrap {
    text-align: center;

    h2 {
      font-family: "Barlow Condensed", sans-serif;
      color: $secondary-color;
      font-size: 30px;
      font-weight: 700;

      @include mobile() {
        font-size: 40px;
      }
    }

    p {
      margin-top: 15px;
      font-size: 18px;
      color: $text-color;
      font-weight: 500;
    }

    .thankyou-card {
      box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.005);
      -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.03);
      -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
      background-color: #fff;

      @include mobile() {
        border: 1px solid #f3b01b75;
        padding: 10px;
        box-shadow: none;
      }

      figure {
        margin-bottom: 0;
        width: 100%;
        height: 230px;

        @include mobile() {
          height: 270px;
          border: 1px solid #f3b01b75;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-content {
        h4 {
          color: $primary-color;
          font-weight: 700;
          margin-bottom: 15px;
        }

        p {
          color: $text-color;
          margin-bottom: 20px;

          @include mobile() {
            margin-bottom: 15px;
          }
        }
      }

      .social-contacts-wrap {
        display: flex;
        justify-content: center;

        @include mobile() {
          flex-direction: column;
          height: 100%;
        }

        button {
          border: 0;
          outline: 0;
          background-color: transparent;
        }

        .share-div {
          background-color: $secondary-color;
          display: flex;
          align-items: center;
          margin: 0 10px;
          margin-bottom: 15px;
          padding: 12px;

          @include mobile() {
            border-radius: 0;
            padding: 12px 10px;
          }

          @include desktop() {
            padding: 12px 15px;
          }

          @include mid-desktop() {
            padding: 13px 20px;
          }

          svg {
            color: #fff;

            @include mobile() {
              margin-right: 8px;
            }

            @include desktop() {
              font-size: 20px;
              margin-right: 20px;
            }

            @include mid-desktop() {
              margin-right: 25px;
              font-size: 22px;
            }
          }

          h5 {
            display: none;

            @include mobile() {
              display: block;
              font-size: 13px;
            }

            @include small-tab() {
              font-size: 14px;
            }

            @include desktop() {
              font-size: 16px;
            }

            margin-bottom: 0;
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
  }

  .thank-you-login-wrap {
    border: 1px solid #9f1d216b;
    background-color: #fff4db;
    padding: 10px;

    p {
      color: $primary-color;
      // font-weight: 500;
    }

    button {
      margin: 0;
    }
  }
}

section.join-family-sec {
  .heading {
    .head-1 {
      font-size: 24px;

      @include mobile() {
        font-size: 40px;
      }
    }

    p {
      font-size: 16px;

      @include mobile() {
        font-size: 20px;
      }
    }
  }

  .join-family-form {
    padding: 10px;

    @include mobile() {
      padding: 20px;
    }

    border: 5px solid $secondary-color;

    .join-family-img {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      margin-right: 12px;

      figure {
        padding: 10px;
        border: 5px solid $secondary-color;
        width: 100%;
        height: 300px;

        img {
          object-fit: cover;

          width: 100%;
          height: 100%;
        }
      }
    }

    .form-part {
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      label {
        color: $secondary-color;
        font-weight: 500;
        padding-bottom: 5px;
      }

      select {
        border-radius: 3px;
        color: $text-color;
        border: 1px solid #7c828b38;
        padding: 8px 5px;

        &:focus-visible {
          outline: 0;
          border: 1px solid $secondary-color;
        }

        // font-weight: 500;
      }

      textArea,
      input {
        border-radius: 3px;
        color: $text-color;
        border: 1px solid #7c828b38;
        padding: 7.5px 10px;
        // font-weight: 500;
        height: 42px;

        &::placeholder {
          color: #7c848d36;
          font-weight: 400;
        }

        &:focus-visible {
          outline: 0;
          border: 1px solid $secondary-color;
        }
      }

      input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
      }

      .input-date {
        position: relative;
        cursor: pointer;
        input {
          width: 100%;
          &._placeholder {
            color: #7c848d36;
          }
        }

        svg {
          pointer-events: none;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 20px;
          color: $text-color;
        }
      }

      .notes-wrap {
        border: 1px solid #f3b01b52;
        border-radius: 3px;
        padding: 4px 5px;
        background-color: #f3b01b17;

        @include small-tab() {
          margin-top: 30px;
        }

        p {
          font-size: 12px;
          margin-bottom: 0;
          color: $text-color;

          span {
            font-weight: 600;
          }
        }
      }

      .radio-btn {
        display: flex;
        align-items: center;

        input {
          margin-right: 5px;
        }

        label {
          padding-bottom: 0;
          margin-right: 10px;
        }
      }

      .add-relative-btn {
        // width: 100px;
        font-size: 14px;
        padding: 5px;
        margin: 0;

        @include mobile() {
          width: 150px;
          font-size: 16px;
        }
      }
    }
  }
}

section.certificate-view-sec {
  padding-top: 140px;

  @include mobile() {
    padding-top: 180px;
  }

  @include big-tab() {
    padding-top: 200px;
  }

  @include mid-desktop() {
    padding-top: 240px;
  }

  figure {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.terms-and-condition-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include small-mobile() {
    flex-direction: row;
  }

  .custom-btn-cls {
    padding: 10px 20px;
  }

  a {
    text-decoration: none !important;
    color: $white-color !important;
  }
}

section.terms-and-condition-sec {
  padding-top: 200px;

  @include big-tab() {
    padding-top: 260px;
  }

  .heading {
    h2 {
      padding-bottom: 10px;
    }

    p {
      text-align: left;
      font-size: 18px;

      @include small-tab() {
        font-size: 20px;
      }
    }
  }
}

section.privacy-policy-sec {
  padding-top: 200px;

  @include big-tab() {
    padding-top: 260px;
  }

  .heading {
    h2 {
      padding-bottom: 10px;
    }

    ol {
      li {
        color: $text-color;
        text-align: left;
        font-size: 18px;

        @include small-tab() {
          font-size: 20px;
        }
      }
    }

    .privacy-para {
      padding-top: 25px;

      p {
        margin-top: 5px;
        margin-bottom: 0;
        text-align: left;
        font-size: 18px;

        @include small-tab() {
          font-size: 20px;
        }
      }

      h5 {
        text-align: left;
        color: $primary-color;
      }
    }

    a {
      text-decoration: underline;
      color: $text-color;

      &:hover {
        color: $secondary-color;
      }
    }

    p {
      text-align: left;
      font-size: 18px;

      @include small-tab() {
        font-size: 20px;
      }
    }
  }
}

section.refund-policy-sec {
  padding-top: 200px;

  @include big-tab() {
    padding-top: 260px;
  }

  .heading {
    h2 {
      padding-bottom: 10px;
    }

    ol {
      li {
        padding: 10px;
        color: $text-color;
        text-align: left;
        font-size: 18px;

        @include small-tab() {
          font-size: 20px;
        }
      }
    }

    p {
      text-align: left;
      font-size: 18px;

      @include small-tab() {
        font-size: 20px;
      }
    }
  }
}

section.thank-you-sec {
  background-color: #f4f4f4;

  .thank-you-wrap {
    .thank-you-info {
      h2 {
        font-family: "Barlow Condensed", sans-serif;
        color: $secondary-color;
        font-size: 30px;
        font-weight: 700;

        @include mobile() {
          font-size: 40px;
        }
      }

      p {
        margin-top: 15px;
        font-size: 18px;
        color: $text-color;
        font-weight: 500;
      }

      .btns-wrap {
        display: flex;
        justify-content: space-between;

        .custom-btn-cls {
          padding: 8px;
          font-size: 13px;

          @include mobile() {
            font-size: 16px;
            padding: 8px 30px;
          }

          @include big-tab() {
            padding: 8px 12px;
          }

          @include desktop() {
            padding: 8px 30px;
          }
        }
      }
    }

    .thank-you-card-wrap {
      text-align: center;

      h2 {
        font-family: "Barlow Condensed", sans-serif;
        color: $secondary-color;
        font-size: 30px;
        font-weight: 700;

        @include mobile() {
          font-size: 40px;
        }
      }

      p {
        margin-top: 15px;
        font-size: 18px;
        color: $text-color;
        font-weight: 500;
      }

      .thankyou-card {
        box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.005);
        -webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.03);
        -moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.05);
        background-color: #fff;

        @include mobile() {
          border: 1px solid $secondary-color;
          padding: 10px;

          box-shadow: none;
        }

        figure {
          margin-bottom: 0;
          width: 100%;
          height: 300px;

          @include mobile() {
            border: 1px solid $secondary-color;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .card-content {
          h4 {
            color: $primary-color;
            font-weight: 600;
            margin-bottom: 15px;
          }

          p {
            color: $text-color;
            margin-bottom: 15px;
          }
        }

        .social-contacts-wrap {
          display: flex;
          justify-content: center;

          @include mobile() {
            flex-direction: column;
            height: 100%;
          }

          .share-div {
            background-color: $secondary-color;
            display: flex;
            align-items: center;
            margin: 0 10px;

            margin-bottom: 15px;
            padding: 12px;

            @include mobile() {
              border-radius: 0;
              padding: 12px 10px;
            }

            @include desktop() {
              padding: 12px 15px;
            }

            @include mid-desktop() {
              padding: 13px 20px;
            }

            svg {
              color: #fff;

              @include mobile() {
                margin-right: 8px;
              }

              @include desktop() {
                font-size: 20px;
                margin-right: 20px;
              }

              @include mid-desktop() {
                margin-right: 25px;
                font-size: 22px;
              }
            }

            h5 {
              display: none;

              @include mobile() {
                display: block;
                font-size: 13px;
              }

              @include small-tab() {
                font-size: 14px;
              }

              @include desktop() {
                font-size: 16px;
              }

              margin-bottom: 0;
              color: #fff;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

section.life-membership-form {
  background-color: #f3f3f3;
  z-index: 11;

  form {
    background-color: #fff;
    z-index: -1;

    .heading {
      text-align: left;
      margin-bottom: 10px;

      h2 {
        font-size: 22px;
        color: $primary-color;

        @include mobile() {
          font-size: 40px;
        }
      }

      h5 {
        color: $secondary-color;
        font-weight: 700;
        font-size: 20px;

        @include mobile() {
          font-size: 25px;
        }
      }

      .form-para {
        color: $primary-color;
        font-weight: 500;
      }

      span {
        color: $secondary-color;
        font-weight: 600;
      }
    }

    padding: 10px;

    @include mobile() {
      padding: 20px;
    }

    @include big-tab() {
      padding: 30px;
    }

    border: 6px solid $secondary-color;

    .form-part {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      position: relative;

      label {
        color: $secondary-color;
        font-weight: 500;
        padding-bottom: 10px;
        // &.hasSelect {
        //     &+div {
        //         height: 40px;
        //         & > div {
        //             box-shadow: unset;
        //             border: 1px solid #7c828b38 !important;
        //             &:hover {
        //                 border-color: unset !important;
        //                 border: 1px solid $secondary-color !important;
        //                 box-shadow: 0 0 0 1px $secondary-color;
        //             }
        //         }
        //     }
        // }
      }

      select {
        border-radius: 3px;
        color: $text-color;
        border: 1px solid #7c828b38;
        padding: 8px 5px;

        &:focus-visible {
          outline: 0;
          border: 1px solid $secondary-color;
        }

        // font-weight: 500;
      }

      textArea,
      input {
        border-radius: 3px;
        color: $text-color;
        border: 1px solid #7c828b38;
        padding: 7.5px 10px;
        // font-weight: 500;
        height: 42px;

        &::placeholder {
          color: #7c848d36;
          font-weight: 400;
        }

        &:focus-visible {
          outline: 0;
          border: 1px solid $secondary-color;
        }
      }

      .notes-wrap {
        border: 1px solid #f3b01b52;
        border-radius: 3px;
        padding: 4px 5px;
        background-color: #f3b01b17;

        @include small-tab() {
          margin-top: 30px;
        }

        p {
          font-size: 12px;
          margin-bottom: 0;
          color: $text-color;

          span {
            font-weight: 600;
          }
        }
      }

      a.donation-link {
        font-size: 13px;
        color: $secondary-color;
        text-align: right;
        position: absolute;
        bottom: -22px;
        right: 0;
        text-decoration: underline;
      }
    }

    .custom-btn-cls {
      z-index: 0;
    }
  }
}

section.dashboard {
  background-color: #f3f3f3;
  padding-top: 250px;

  .user-details-wrap {
    .user-details {
      border-left: 4px solid $secondary-color;
      padding: 10px 0;
      padding-left: 20px;

      ul {
        li {
          strong {
            color: $primary-color;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

section.dashboard-table {
  padding-top: 150px;

  @include big-tab() {
    padding-top: 250px;
  }

  h5.table-title {
    color: $primary-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;

    @include small-tab() {
      font-size: 20px;
    }
  }

  .top-head {
    display: block;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.103);

    @include big-tab() {
      border-bottom: 0;
      display: flex;
    }
  }

  .table-heading {
    border-top: 1px solid rgba(0, 0, 0, 0.204);
    border-bottom: 1px solid rgba(0, 0, 0, 0.204);

    h5 {
      color: $primary-color;
      font-size: 20px;
      margin-bottom: 0;
    }
  }

  .table-content {
    .table-row {
      border-bottom: 1px solid rgba(0, 0, 0, 0.103);
      height: 100%;

      .form-field {
        display: flex;
        align-items: top;

        p {
          color: $text-color;
          font-size: 16px;

          @include mobile() {
            font-size: 18px;
          }

          margin-bottom: 0;

          &.failed {
            color: $primary-color;
          }

          &.success {
            color: $secondary-color;
          }
        }

        h5 {
          color: $primary-color;
          padding-right: 10px;
          font-size: 16px;

          @include mobile() {
            font-size: 18px;
          }

          @include big-tab() {
            font-size: 20px;
            display: none;
            padding-right: 0;
          }
        }

        .download-btns {
          display: flex;

          button {
            margin-top: 0;
            padding: 5px 10px;
            position: relative;
            display: flex;
            align-items: center;

            &:hover {
              span {
                opacity: 1;
              }
            }

            span {
              position: absolute;
              background-color: #9f1d211f;
              top: 50px;
              left: 50%;
              transform: translate(-50%);
              width: 200px;
              color: $primary-color;
              font-size: 14px;
              padding: 5px 0;
              pointer-events: none;
              opacity: 0;
              transition: 0.4s;

              &::before {
                content: "";
                left: 50%;
                width: 10px;
                height: 10px;
                background-color: #9f1d211f;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                position: absolute;
                transform: translateX(-50%);
                top: -10px;
              }
            }
          }
        }

        .processing {
          background-color: $secondary-color;
          text-align: center;
          color: $white-color;
          padding: 5px 10px;
          text-transform: uppercase;
        }
      }
    }
  }

  ul.pagination {
    justify-content: center;
    margin-top: 25px;

    @include small-tab() {
      margin-top: 40px;
    }

    li {
      margin: 0 10px;
      cursor: pointer;

      &:focus-visible {
        outline: none;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      a,
      button {
        background-color: transparent;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s;

        &.active {
          transform: scale(1.1);
          background-color: $secondary-color;
          color: $white-color;
        }

        &.arrow {
          border-radius: 0;
          transform: scale(1.2);
          padding: 0.35rem 0.45rem;
          background-color: #a4990535;
          transition: 0.4s;

          &:hover {
            transform: scale(1.1);
            background-color: $primary-color;

            svg {
              color: $white-color;
            }
          }

          svg {
            color: $primary-color;
          }
        }

        &:hover {
          background-color: $secondary-color;
          color: $white-color;
          transform: scale(1.1);
        }

        border: 0;
        color: $primary-color;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.fixed-link {
  position: fixed;
  width: 50px;
  height: 50px;
  right: 20px;
  bottom: 20px;
  background-color: #25d366;
  border-radius: 50%;
  z-index: 200;

  a {
    @include center();
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  svg {
    font-size: 30px;
    color: $white-color;
  }
}

.live-video-wrap {
  padding: 10px;
  border: 5px solid $secondary-color;
  position: relative;

  iframe {
    width: 100%;
    height: 200px;

    @include mobile() {
      height: 300px;
    }

    @include big-tab() {
      height: 550px;
    }
  }

  .news-details {
    position: absolute;
    top: 9px;
    background: linear-gradient(0deg, #000000 0%, #00000000 100%);
    left: 10px;
    height: 88.8%;
    width: 94.1%;
    pointer-events: none;
    display: flex;
    align-items: flex-end;

    @include small-mobile() {
      width: 95.1%;
    }

    @include mobile() {
      height: 92.8%;
      width: 97.1%;
    }

    @include big-tab() {
      height: 95.8%;
      width: 97.5%;
    }

    @include desktop() {
      width: 98.2%;
    }

    .news-details-wrap {
      width: 100%;
      padding-left: 10px;

      @include small-tab() {
        padding-left: 20px;
      }

      h4 {
        color: $white-color;
        text-transform: capitalize;
        font-size: 20px;

        @include small-tab() {
          font-size: 24px;
        }
      }

      p {
        color: $white-color;
        font-size: 16px;
        display: none;

        @include mobile() {
          display: block;
        }

        @include small-tab() {
          font-size: 18px;
        }
      }
    }
  }
}

section.sitemap {
  .sitemap-content {
    h4 {
      font-size: 24px;
      text-transform: uppercase;
      color: $primary-color;
      font-weight: 600;
    }

    ul {
      a {
        text-transform: uppercase;
      }

      &.main_menu {
        & > li {
          & > a {
            color: $text-color;
            font-size: 18px;
            padding-left: 25px;
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              background-image: url("../../public/images/feather.svg");
              background-position: center;
              background-size: cover;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }

            &:hover {
              color: $secondary-color;
            }
          }
        }
      }

      &.sub_menu {
        margin-top: 10px;
        margin-left: 25px;

        & > li {
          padding-left: 15px;
          position: relative;

          &::before {
            content: "";
            width: 8px;
            height: 8px;
            position: absolute;
            border-radius: 50%;
            background-color: $secondary-color;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.campaign-benefits {
  background-color: #fff5da;

  .campaign-benefits-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      img {
        width: 300px;
      }
    }

    .benefits-content {
      h4 {
        color: $primary-color;
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;

        @include mobile() {
          font-size: 22px;
        }

        @include big-tab() {
          font-size: 24px;
        }
      }

      p {
        color: $dark-text-color;
        margin-top: 20px;
        text-align: center;
        margin-bottom: 0;
        font-size: 20px;

        @include mobile() {
          font-size: 22px;
        }

        @include big-tab() {
          font-size: 24px;
        }
      }
    }
  }
}

.campaign-request {
  background-color: #e8edf0;

  p {
    color: $text-color;
    text-align: center;
    font-size: 18px;

    @include mobile() {
      font-size: 20px;
    }

    @include small-tab() {
      font-size: 22px;
    }

    a {
      color: $dark-text-color;
      transition: 0.4s;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}

section.campaign-articles {
  .campaign-articles-wrap {
    .splide {
      .splide__slide {
        figure {
          border: 2px solid $secondary-color;
          padding: 8px;
          width: 100%;
          height: 350px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
    }
  }
}

section.campaign-support {
  .support-btn-wrap {
    padding-top: 24px;
    position: relative;

    @include mobile() {
      padding-top: 60px;
    }

    @include center();

    figure {
      position: absolute;
      top: 0%;
      right: 5%;
      animation: arrow 2s infinite ease-in alternate;

      @include small-mobile() {
        right: 18%;
      }

      @include small-tab() {
        right: 22%;
      }

      @include big-tab() {
        right: 28%;
        top: 10%;
      }

      @include desktop() {
        right: 33%;
      }

      img {
        width: 50px;

        @include mobile() {
          width: 80px;
        }
      }
    }

    button {
      @include small-tab() {
        font-size: 24px;
      }

      @include big-tab() {
        padding: 7px 20px;
      }
    }
  }
}

section.campaigns-wrap {
  .campaign-card-wrap {
    .campaign-card {
      box-shadow: 0 0 20px 10px #cfcfcf45;
      height: 100%;

      .card-figure {
        position: relative;

        figure {
          width: 100%;
          height: 250px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .campaign-title {
          background-color: $white-color;
          width: 80%;
          position: absolute;
          bottom: 0;
          padding: 10px;

          @include mobile() {
            padding: 15px;
          }

          h4 {
            margin-bottom: 0;
            font-weight: 600;
            text-transform: capitalize;
            color: $secondary-color;
            font-size: 20px;

            @include mobile() {
              font-size: 22px;
            }

            @include big-tab() {
              font-size: 24px;
            }
          }
        }
      }

      .short-description {
        padding: 15px;
        padding-top: 10px;

        h6 {
          color: $text-color;
          font-size: 16px;

          @include mobile() {
            font-size: 18px;
          }
        }
      }

      &.skeleton {
        box-shadow: unset;

        .short-description {
          border: 1px solid #ebebeb;
          border-top: 0;
        }

        .react-loading-skeleton {
          border-radius: 0;
        }
      }
    }
  }
}

.downloading-wrap {
  width: 100%;
  height: 100vh;
  @include center();
  background-color: #f5b01b0d;

  h2 {
    color: $primary-color;
  }
}

section.shop-wrap {
  .heading {
    p.highlighted-text {
      color: $primary-color;
      font-weight: 600;
      font-style: italic;
    }
  }

  .shop-slider-wrap {
    border: 5px solid $secondary-color;
    padding: 20px;
    background-color: #f4f4f4;

    .splide {
      .splide__slide {
        a {
          display: block;

          figure {
            width: 100%;

            @include big-tab() {
              height: 400px;
            }

            @include desktop() {
              height: 450px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              filter: drop-shadow(7px 13px 16px #686565);
            }
          }
        }
      }
    }
  }
}

.shop-form {
  border: 5px solid $secondary-color;
  padding: 15px;

  @include mobile() {
    padding: 20px;
  }

  figure {
    width: 100%;

    @include big-tab() {
      height: 400px;
    }

    border: 5px solid $secondary-color;
    background-color: #f4f4f4;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: drop-shadow(7px 13px 16px #686565);
    }
  }
}

.order-summary-wrap {
  border: 1px dashed $secondary-color;
  background-color: #f3b01b17;
  padding: 10px;

  @include mobile() {
    padding: 20px;
  }

  .order-summary-card {
    @include mobile() {
      border-right: 1px dashed $secondary-color;
    }

    &._second {
      border-right: 0;

      @include big-tab() {
        border-right: 1px dashed $secondary-color;
      }
    }

    p {
      margin-bottom: 0;
      color: $text-color;
      font-size: 16px;
    }

    h4 {
      font-size: 20px;
      color: $primary-color;
    }
  }
}

.order-details-wrap {
  .details-table {
    margin-top: 25px;

    .details-card {
      border-bottom: 1px dashed $text-color;

      p {
        color: $text-color;
        margin-bottom: 0;
        padding: 10px 0;
      }

      h5 {
        color: $dark-text-color;
        margin-bottom: 0;
        padding: 10px 0;
      }

      .last {
        text-align: right;
      }

      h5,
      p {
        &:not(.last) {
          font-weight: 600;
        }
      }
    }
  }
}

.eightyG-modal {
  .modal-content {
    background-color: #fffbf3;
    border: 4px solid $secondary-color !important;

    .modal-header {
      border-bottom: 0;
    }

    .modal-body {
      text-align: center;

      p {
        color: $primary-color;
        font-weight: 600;
        font-size: 20px;
        line-height: 1.8;

        span {
          color: black;
        }
      }
    }
  }
}

section.csr-annadan {
  background-color: #fffbf2;

  figure {
    border: 6px solid $secondary-color;
    padding: 10px;
    width: 100%;
    background-color: $white-color;

    @include small-tab() {
      width: 70%;
    }

    @include big-tab() {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .heading {
    h4 {
      color: $primary-color;
      font-size: 20px;
      font-weight: 700;
      margin-top: 20px;
      text-transform: uppercase;

      @include small-tab() {
        font-size: 24px;
      }
    }

    & > p {
      color: $dark-text-color;
    }

    ul {
      margin-top: 30px;

      p {
        line-height: 1.6;
        font-size: 16px;

        @include mobile() {
          font-size: 18px;
        }
      }
    }
  }

  .csr-wrap {
    h4 {
      text-align: center;
      color: $primary-color;
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;

      @include mobile() {
        font-size: 20px;
      }
    }

    h5 {
      text-align: center;
      color: $dark-text-color;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;

      @include mobile() {
        font-size: 18px;
      }
    }

    .tab-col {
      border-bottom: 1px solid $secondary-color;
      border-left: 1px solid $secondary-color;
      border-right: 1px solid $secondary-color;
      padding: 10px;
      height: 100%;
      background-color: $white-color;
      @include center();
    }

    ._header {
      background-color: #f3b01b5c;
      padding: 15px 0;
      border: 1px solid $secondary-color;
      border-bottom: 0;
    }

    p {
      margin-bottom: 0;
      text-align: center;
      color: $text-color;
    }
  }
}

section.future-plan {
  padding: 60px 0;
  // background-color: #f3f3f3;
  background-color: $white-color;

  @include small-tab() {
    padding: 80px 0;
  }

  p {
    font-size: 18px;

    @include mobile() {
      font-size: 20px;
    }
  }

  .dummy-line::after {
    content: "";
    position: absolute;
    width: 5.5px;
    height: 70%;
    background-color: $secondary-color;
    display: none;

    @include big-tab() {
      display: block;
      margin-left: 3rem;
    }
  }
}

section.csr-partners {
  &._query {
    @include small-tab() {
      background-position: 0 -150px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #00000069;
    pointer-events: none;
    z-index: -1;
  }

  .csr-partners-slider {
    .partner-slide {
      // border: 4px solid $secondary-color;
      @include center();
      padding: 10px;
      background-color: $white-color;

      figure {
        width: 100%;
        height: 100px;
        @include center();

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  ul {
    li {
      &::before {
        background-image: url("../../public/images/feather-white.svg");
      }

      p {
        color: $white-color;

        a {
          color: $white-color;

          &:hover {
            color: $secondary-color;
          }
        }
      }
    }
  }
}

section.green-init {
  background-color: #fffbf2;

  .heading {
    h4 {
      color: $primary-color;
      font-size: 20px;
      font-weight: 700;
      margin-top: 20px;
      text-transform: uppercase;

      @include small-tab() {
        font-size: 24px;
      }
    }
  }

  .green-init-tab-wrap {
    .green-init-tabs {
      justify-content: center;
      border: 0;
      flex-direction: column;

      @include small-tab() {
        flex-direction: row;
      }

      .nav-item {
        border: 0;
        margin-bottom: 0;

        &:first-child {
          @include small-tab() {
            button {
              padding-left: 0;

              &::before {
                transform: unset;
                left: 0;
                width: 90%;
              }
            }
          }
        }

        &:last-child {
          @include small-tab() {
            button {
              padding-right: 0;

              &::before {
                transform: unset;
                left: unset;
                right: 0;
                width: 90%;
              }
            }
          }
        }

        button {
          padding: 10px;
          border-radius: 0;
          border: 0;
          outline: none;
          color: $dark-text-color;
          font-weight: 600;
          border-bottom: 2px solid #e1e1e1;
          position: relative;
          width: 100%;

          @include small-tab() {
            width: auto;
            padding: 6px 30px;
          }

          &::before {
            content: "";
            position: absolute;
            background-color: $secondary-color;
            height: 3px;
            left: 50%;
            transform: translateX(-50%);
            bottom: -2px;
            opacity: 0;
            transition: 0.4s;
            width: 50%;

            @include small-tab() {
              width: 70%;
            }
          }

          &.active {
            color: $secondary-color;
            background-color: transparent;

            &::before {
              opacity: 1;
            }
          }
        }
      }
    }

    .tab-content {
      padding-top: 30px;

      figure {
        padding: 10px;
        width: 100%;

        @include small-tab() {
          width: 50%;
        }

        @include big-tab() {
          width: 100%;
        }

        img {
          width: 100%;
        }

        &._first {
          @include desktop() {
            height: 350px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .tab-inner-content {
        h4 {
          color: $secondary-color;
          font-weight: 600;
          margin-bottom: 15px;
          font-size: 20px;

          @include small-tab() {
            font-size: 24px;
          }
        }

        p {
          font-size: 18px;
          color: $text-color;

          @include small-tab() {
            font-size: 20px;
          }
        }
      }
    }
  }
}

section.partner-with-us {
  background-color: #f3f3f3;

  figure {
    width: 100%;
    background-color: $white-color;
    padding: 10px;
    box-shadow: 5px 5px 30px 0px #93939378;

    @include mobile() {
      width: 70%;
    }

    @include big-tab() {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  ol.main-list {
    padding-left: 12px;
    margin-top: 20px;

    li {
      font-size: 20px;
      margin-bottom: 15px;
      color: $text-color;
    }
  }
}

section.value-education {
  .heading {
    h4 {
      color: $primary-color;
      font-size: 20px;
      font-weight: 700;
      margin-top: 10px;
      text-transform: uppercase;

      @include small-tab() {
        font-size: 24px;
      }
    }
  }
}

section.education-need {
  background-color: #f3f3f3;

  figure {
    width: 100%;
    padding: 10px;
    border: 4px solid $secondary-color;

    @include small-tab() {
      width: 70%;
    }

    @include big-tab() {
      width: 100%;
    }

    img {
      width: 100%;
    }
  }
}

section.only-content {
  &.dark {
    background-color: #f3f3f3;
  }

  .notes-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    small {
      color: $primary-color;
      font-style: italic;
      margin-bottom: 10px;
      font-weight: 500;
    }
  }
}

section.curriculum {
  background-color: $secondary-color;

  .curriculum-card {
    background-color: $white-color;
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
    padding: 15px 25px;
    margin-bottom: 35px;

    .icon {
      border-right: 1px solid #b5b5b5;

      figure {
        width: 34px;

        img {
          width: 100%;
        }
      }
    }

    .content {
      padding-left: 10px;

      h4 {
        margin-bottom: 0;
        color: $primary-color;
        font-size: 20px;

        @include small-tab() {
          font-size: 22px;
        }
      }
    }
  }
}

section.goal-cost {
  .goal-card {
    padding: 25px;
    background-color: #9f1d210f;

    @include small-tab() {
      padding: 30px;
    }

    p {
      margin-bottom: 0;
      font-size: 18px;
      color: $text-color;

      @include small-tab() {
        font-size: 20px;
      }
    }

    h4 {
      color: $dark-text-color;
      font-weight: 600;
      font-size: 22px;

      @include small-tab() {
        font-size: 24px;
      }
    }
  }
}

section.csr-testimonial {
  background-color: #fffbf2;

  &._students {
    background-color: #f3f3f3;
  }

  .csr-test-wrap {
    .testimonial-slide {
      background-color: $white-color;
      padding: 20px;

      &.static {
        height: 100%;

        p {
          font-size: 18px;
          color: $text-color;
        }

        figure {
          background-color: #e3e8eb;

          @include small-tab() {
            width: 100%;
            height: auto;
          }

          &._first {
            background-color: #eff1eb;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: scale(0.95);
          }
        }
      }

      figure {
        width: 140px;
        height: 140px;
        overflow: hidden;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      h5 {
        font-size: 22px;
        color: $primary-color;
        margin-bottom: 10px;
        font-weight: 500;
        font-style: italic;
      }

      h6 {
        margin-bottom: 10px;
        font-weight: 500;
        color: $primary-color;
        font-size: 18px;
      }
    }

    .splide__pagination {
      bottom: -40px;

      li {
        button {
          width: 40px;
          height: 4px;
          background-color: #9f1d2175;
          transform: unset;
          border-radius: 0;
          margin: 0 6px;

          &.is-active {
            background-color: $primary-color;
          }
        }
      }
    }
  }
}

.csr-new {
  .primary-heading {
    font-size: 32px;

    @include small-tab() {
      font-size: 38px;
    }

    @include small-desktop() {
      font-size: 45px;
    }
  }

  .secondary-heading {
    font-size: 26px;

    @include small-tab() {
      font-size: 35px;
    }

    @include small-desktop() {
      font-size: 40px;
    }
  }
}

section.kartik-deepdaan-calendar {
  .grid-container-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(1, 30px);
    gap: 8px;
    margin-bottom: 8px;
    .grid-item-days {
      text-align: center;
      color: #989595;
      font-weight: 500;
    }
  }
  .calendar {
    &.grid-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(12, 120px);
      gap: 8px;
      @include big-tab() {
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(5, 120px);
      }
    }

    .grid-item {
      .date-info-block {
        &.booked {
          color: #bbbbbb !important;
          .event-date {
            color: #bbbbbb !important;
          }
        }

        background-color: #f4f4f4;
        padding: 8px;
        // text-align: center;
        width: 100%;
        height: 100%;
        transition: 0.4s;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .event-date {
          color: #989595;
          font-weight: 500;
          &.month {
            font-size: 0.7rem;
          }
          &.day {
            font-size: 0.7rem;
          }
          @include big-tab() {
            &.month {
              font-size: 0.9rem;
            }
            &.day {
              font-size: 0.8rem;
            }
          }
        }
        .event-title {
          font-size: 0.7rem;
          font-weight: 500;
          margin-top: 10px;
          color: $secondary-color;
          @include mobile() {
            font-size: 0.9rem;
          }
        }
        .event-amount {
          margin-top: auto;
        }
      }
      label {
        @include big-tab() {
          &:hover {
            cursor: pointer;
            background-color: #fff4daf0;
          }
        }
      }

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        top: 0;

        &:checked + label {
          position: relative;
          background-color: #fff4daf0;
          &::before {
            content: "✔";
            position: absolute;
            bottom: 10px;
            right: 6px;
            height: 17px;
            width: 17px;
            @include center();
            font-size: 10px;
            color: $white-color;
            background-color: $secondary-color;
            opacity: 1;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .summary {
    border: 2px solid $secondary-color;
    display: flex;
    height: 100%;
    flex-direction: column;
    .h5 {
      text-align: center;
      background-color: $secondary-color;
      color: $white-color;
      padding: 5px 5px 7px 5px;
    }
    .selected-items {
      padding: 10px;
      flex: 92%;
    }
    .selected-items-pay {
      padding: 0 10px 10px 10px;
      .total-amount {
        flex: 8%;
        text-align: center;
      }
      button {
        position: fixed;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        @include big-tab() {
          position: relative;
          bottom: unset;
          left: unset;
          transform: none;
          width: 100%;
        }
      }
    }
    .selected-events-list {
      max-height: 100%;
      overflow-y: scroll;
    }
  }
}

section.login_page {
  height: 71vh;
  position: relative;
  background: linear-gradient(0deg, #9f1d2110 0%, #ffffff 100%);
  &::before {
    content: '';
    width: 50%;
    height: 50%;
    background-image: url('../../public/images/bg-icon.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    visibility: .5;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: none;
    opacity: .2;
    @include small-tab() {
      display: block;
    }
  }
}

.label-text {
  color: #F3B01B;
  font-weight: 500;
  padding-bottom: 5px;
  span {
    &::after {
      content: '*';
      color: red;
    }
  }
  small {
    display: inline-block;
    margin-left: 10px;
    font-size: 12px;
    color: #aaa !important;
  }
}

.custom-uploads {
  border: 1px solid #E2E4E6;
  height: 125px;
  border-radius: 4px;
  position: relative;
  margin-bottom: 30px;
  @include mobile() {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #babac3;
    text-align: center;
  }
  label {
    width: 100%;
    height: 100%;
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  small {
    font-size: 10px;
    color: #ccc;
  }
}

.preview-wrap {
  border: 1px solid #E2E4E6;
  height: 125px;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
  figure {
    height: 100%;
    padding: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}